import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from '../services/image.service';
import { AuthService } from '../services/auth.service';
import { Game } from '../classes/Game';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-image-multi-editor',
  templateUrl: './image-multi-editor.component.html',
  styleUrls: ['./image-multi-editor.component.scss']
})
export class ImageMultiEditorComponent implements OnInit {

	@Input() game: Game;
	@Input() team: TeamRecord;

	images: Image[] = [];

	loading = "Loading Images...";
	err = "";

	context: 'game' | 'team' | 'profile';
	id: string;

	focusImg: number;

	queueDelete: boolean;

	constructor(private imgServe: ImageService, private auth: AuthService) { }

	ngOnInit(): void {
		if (this.game) this.context = 'game';
		else if (this.team) this.context = 'team';
		else this.context = 'profile';

		this.getImages();
	}

	getImages() {
		switch (this.context) {
			case 'game':
				this.getGameImages();
				this.id = this.game.gameid;
				break;
			case 'team':
				this.getTeamImages();
				this.id = this.team.teamid;
				break;
			default:
				let p = this.auth.getPlayer()
				this.id = p ? p.playerid : '';
				this.getProfileImage();
		}
	}

	setFocus(index: number) {
		if (this.focusImg !== index) {
			this.focusImg = index;
			this.queueDelete = false;
		}
		else this.focusImg = undefined;
	}

	removeFocus(event) {
		this.focusImg = undefined;
		this.queueDelete = false;
		event.stopPropagation()
	}

	startDelete(event) {
		this.queueDelete = true;
		event.stopPropagation();
	}

	deleteImage(index: number, event) {
		event.stopPropagation()
		this.loading = "Deleting image..."
		this.err = ""
		this.imgServe.deleteImage(
			this.context, 
			this.game.gameid, 
			this.images[index].image_code)
			.pipe(finalize(() => this.loading = ""))
			.subscribe(
				(res: Image[]) => {
					this.focusImg = undefined;
					this.queueDelete = false;
					this.images = res;
				},
				err => this.err = err.error.detail)
	}

	getGameImages() {
		this.loading = "Loading Images...";
		this.imgServe.gameImages(this.game.gameid)
			.pipe(finalize(() => this.loading = ''))
			.subscribe(
				(res: Image[]) => this.images = res,
				err => this.err = err.error.detail)
	}

	getTeamImages() {
		console.log("TODO: create function for fetching team images.")
	}

	getProfileImage() {
		console.log("TODO: create function for fetching profile image.")
	}

	uploadImages(files: FileList | File[]) {
		this.loading = "Uploading your Images..."
		let toUpload: File[] = [];
		for (let i = 0; i < files.length; i++) {
			let f = files[i];
			if (f.type.match(/^image\//)) {
				if (f.size > 5242880) {
					console.log(f.size)
					this.err = `${f.name} is too large.`
					this.err = `${this.err} Please choose an image that 5MB or less.`
					this.loading = ""
					return
				}
				toUpload.push(files[i]);
			}
		}
		if (toUpload.length) {
			this.imgServe.uploadImage(this.context, this.id, toUpload.shift())
				.subscribe(
					(res: Image[]) => {
						if (toUpload.length) this.uploadImages(toUpload);
						else {
							this.images = res;
							this.loading = "";
							this.err = '';
						}
					},
					err => {
						this.err = err.error.detail;
						this.loading = '';
					})
		}
		else {
			this.loading = "";
			return;
		}
	}



}
