import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-team-name',
  templateUrl: './team-name.component.html',
  styleUrls: ['./team-name.component.scss']
})
export class TeamNameComponent implements OnInit {

  @Input() model!: string;
  @Input() maxLength: number = 125;
  @Output() modelChange = new EventEmitter<string>();
  charCount: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.charCount = this.model?.length || 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['model']) {
      this.updateCharCount();
    }
  }

  updateCharCount() {
    this.charCount = this.model?.length || 0;
  }

  onModelChange(newValue: string) {
    this.model = newValue;
    this.modelChange.emit(this.model);
    this.updateCharCount();
  }

}
