import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FileUploader } from 'ng2-file-upload'
import { FormGroup } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ImageCropperModalComponent } from './image-cropper-modal/image-cropper-modal.component';
import { fromEvent } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import * as jwtJsDecode from 'jwt-js-decode';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  data = {image: ''};
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isImageLoaded : boolean = false;

  userPreferencesForm: FormGroup;
  // userData = this.authService.userInfo.getValue();
  userTitle: string;
  saveEnabled = false;
  uploader: FileUploader = new FileUploader({});

  imageSrc: any;
  name: string;
  email: string;
  phone: string;

  contactVerifying: string;

  imageChanged: boolean;
  files: File[] = [];
  fileBlob: string;

  edit: string;
  invalidInput: boolean;
  isSending: boolean;
  verifying: boolean;
  confirming: boolean;

  err = "";
  msg = "";

  timer;
  resendInSec = 0;

  loading = true;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.authService.updateToken()
    	.subscribe(
    		(res: {status: string, message: string}) => {
    			this.authService.setToken(res.message)
    			this.setDataFromToken();
    			this.loading = false;
    		err => this.err = err.error.detail})
  }

  setDataFromToken() {
    const token = this.authService.getToken();
    const decoded = this.parseJwt(token);
    this.name = decoded.player_name;
    this.email = decoded.email;
    this.phone = decoded.phone;
  }

 parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  cancelChange() {
    this.setDataFromToken();
    this.edit = "";
    this.verifying = false;
    this.contactVerifying = ""
  }

  updateName() {
    this.err = "";
    this.edit = "";
    this.msg = "Saving..."
    this.authService.enterName(this.name)
      .subscribe((res: any) => {
        this.authService.setToken(res.message);
        this.msg = "Name changed successfully!";
      },
      err => {
        this.msg = "";
        this.err = "Failed to update name.";
        this.setDataFromToken();
      })
  }

  validateContact(type: string) {
  	if (type === 'email') {
  		// tslint:disable-next-line:max-line-length
  		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    	return !(this.email && re.test(String(this.email).toLowerCase()));
  	}
  	if (type === 'phone') {
  		return !(this.phone.length === 10)
  	}
  }

  startUpdate(type: string) {
  	this.invalidInput = this.validateContact(type)
  	if (this.invalidInput) return;
  	this.loading = true;
  	this.isSending = true;
  	this.contactVerifying = this[type]
  	this.authService.addContact(type, this[type])
  		.pipe(finalize(() => {
  			this.loading = false;
  			this.isSending = false;
  		}))
  		.subscribe(
  			res => {
  				this.verifying = true;
  				this.resendInSec = 60;
  				this.timer = setInterval(this.decrementSeconds.bind(this), 1000);
  			},
  			err => this.err = err.error.detail)
  }

  decrementSeconds() {
  	this.resendInSec--;
  	if (this.resendInSec < 1) clearInterval(this.timer);
  }

  confirmUpdate(code) {
  	if (!code) return
  	this.loading = true
    this.err = ""
    this.msg = ""
    this.confirming = true
  	this.authService.verifyUpdate(code)
  		.pipe(finalize(() => {
  			this.loading = false;
  			this.confirming = false;
  		}))
  		.subscribe(
  			(res: {success: string, message: string}) => {
  				this.authService.setToken(res.message)
  				this.setDataFromToken()
  				this.msg = `${this.edit === 'email' ? 'Email' : 'Phone number'} added successfully!`
  				this.cancelChange()
  			},
  			err => this.err = err.error.detail)
  }

  setEmail(e: string) {
  	this.email = e;
  	this.invalidInput = false;
  }

  setPhone(p: string) {
  	this.phone = p;
  	this.invalidInput = false;
  }

// openEditor() {
//   const dialogRef = this.dialog.open(ImageCropperComponent, {
//     width: '250px',
//     data: {
//       image: this.imageSrc
//     },
//   });

//   dialogRef.afterClosed().subscribe((name) => {
//     if (name) {
//       // this.authService.enterName(name).subscribe(nameChangeResult => {
//       //   this.authService.setToken(nameChangeResult.message);
//       //   console.log('Name Change Result:', nameChangeResult);
//       // });
//     }
//   });
// }

onSelect(event) {
  console.log(event);
  this.files.push(...event.addedFiles);
  this.fileBlob = URL.createObjectURL(event.addedFiles?.[0]);
}

onRemove(event) {
  console.log(event);
  this.files.splice(this.files.indexOf(event), 1);
}


fileChangeEvent(event: any): void {
  this.imageChangedEvent = this.data.image;
}

imageCropped(event: ImageCroppedEvent) {
  this.croppedImage = event.base64;
}

imageLoaded() {

}

cropperReady() {
this.isImageLoaded = true;
}

loadImageFailed() {
  // show message
}


save() {
// this.authService.updateUserImage(this.croppedImage).subscribe(data => {
//   if (data && data.Table && data.Table.length > 0 && data.Table[0].Result === "OK") {
//     this.authService.saveUserInfo(this.data.user);
//     this.dialogRef.close(this.croppedImage);
//   }
// });

}

cancel() {
// this.dialogRef.close();
}


uploadFile(event) {
  const currentNode = event.target;
  const elem = currentNode.querySelector('input#avatar-file-upload');
  if (elem) {
    (elem as HTMLInputElement).click();
  }
}

imageCropper(image) {
  return this.dialog
    .open(ImageCropperModalComponent, {
      data: {
        image,
      }
    })
    .afterClosed()
    .subscribe(result => {
      if (result) {
        this.imageSrc = result;
      }
    });
}


async handleFiles(event) {
  const fileInputElement = event.target;
  const file = fileInputElement.files[0];

  // this.imageCropper(file);

  if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/bpm') {
    const result = await this.compress(file, 600, 400);
    this.imageCropper(result);
  }
  else {
    alert(`Try uploading your avatar again using the following file types: .jpg, .png, .gif`)
    // this.commonModalProvider.openAlertWithErrorAutoHideDuration (
    //   "Upload Failed",
    //   `Try uploading your avatar again using the following file types: .jpg, .png, .gif`,
    //   "error",
    //   5000);
  }
  event.target.value = null;
}

compress(file: File, toWidth: number, toHeight: number): Promise<any> {
  const width = toWidth; // For scaling relative to width
  const height = toHeight;
  const reader = new FileReader();
  reader.readAsDataURL(file);

  // return new Observable(observer => {
  // return fromEvent(reader, 'onload')
  // .pipe(
  //   map(ev => {
  // reader.onload = ev => {
  const img = new Image();
  // img.src = (ev.target as any).result;
  img.src = URL.createObjectURL(file);
  return new Promise( (resolve, reject) => {
  img.onload = () => {
    let imgOrientation = 'portrait';
    if (img.width > img.height) {
      imgOrientation = 'landscape';
    }
    const elem = document.createElement('canvas');

    if (imgOrientation === 'landscape') {
      const scaleFactor = width / img.width;
      elem.width = width;
      elem.height = img.height * scaleFactor;
    } else {
      const scaleFactor = height / img.height;
      elem.height = height;
      elem.width = img.width * scaleFactor;
    }
    const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
    ctx.drawImage(img, 0, 0, elem.width, elem.height);
    ctx.canvas.toBlob(
      blob => {
        resolve(
        // observer.next(
          new File([blob], file.name, {
            type: 'image/png',
            lastModified: Date.now(),
          }),
        );
      },
      'image/png',
      1,
    );
  };
  (reader.onerror = error => reject(error));
});
}

}
