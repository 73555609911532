import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-stub',
  templateUrl: './team-stub.component.html',
  styleUrls: ['./team-stub.component.scss']
})
export class TeamStubComponent implements OnInit {

	@Input() team: TeamRecord
	@Input() isInvited: boolean;
	@Input() isRequested: boolean;

	constructor(private router: Router) { }

	ngOnInit(): void {

	}

	toTeam(kt_team_id: string) {
		this.router.navigate([`/team/${kt_team_id}`]);
	}

	pendingRequest() {
		if (!this.isInvited && !this.isRequested) {
			for (let m of this.team.members) {
				if (!m.joined && m.requested) return true;
			}
		}
		return false;
	}

}
