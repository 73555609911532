<div class="team-container">
	<div class="details-container">
		<div class="image-container">
			<div class="team-image">
				<img src="{{team.team_image}}">
			</div>
		</div>
		<div class="team-details">
			<div>
				<h2>#{{team.kt_team_id}} {{team.team_name}}</h2>
				<p *ngIf="!isInvited">{{team.members.length}} Members</p>
				<p *ngIf="isInvited" class="pending">You {{isRequested ? 'are Invited!' : 'Requested to Join'}}</p>
				<p *ngIf="pendingRequest()" class="pending">Pending Requests</p>
			</div>
			<div class="team-view">
				<button (click)="toTeam(team.kt_team_id)">View</button>
			</div>
		</div>
	</div>
</div>
