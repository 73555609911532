<div class="survey--feed-container" *ngIf="!loading; else loadMsg">
	<div *ngIf="!previewMode; else preview">
		<div *ngIf="question; else noSurvey">
			<h3 class="no-mb">Question {{currentRes}}/{{maxDailyRes}}</h3>
			<ng-container *ngTemplateOutlet="qForm"></ng-container>
		</div>
	</div>
	<p class="error-message" *ngIf="err">{{err}}</p>
</div>

<ng-template #preview>
	<div *ngIf="question; else donePreview">
		<ng-container *ngTemplateOutlet="qForm"></ng-container>
	</div>
</ng-template>

<ng-template #loadMsg>
	<div class="survey--load-message">
		<h2 class="lg-mt">{{loading}}</h2>
	</div>
</ng-template>

<ng-template #noSurvey>
	<div class="survey--no-questions">
		<h2>You've Answered All Our Questions For Now</h2>
		<h3>Check back tomorrow for more questions and <a href="https://kingtrivia.com/where-to-play?game=slam" target="_blank">Play Survey Slam</a> to see the results!</h3>
	</div>
</ng-template>

<ng-template #donePreview>
	<div class="survey--no-questions" [ngClass]="!previewed ? 'hidden' : ''">
		<h2>Thanks for sharing your opinion!</h2>
		<h3><a href="https://play.kingtrivia.com/survey" target="_blank">Take our daily survey</a> for more fun conversation starters.</h3>
	</div>
</ng-template>

<ng-template #qForm>
	<h2>{{question.question_text}}</h2>
	<div *ngIf="question.options.length; else freeResponse">
		<ul class="survey--options-list">
			<li *ngFor="let o of question.options" (click)="respond(o)">{{o}}</li>
		</ul>
	</div>
	<div class="survey--button-container">
		<button (click)="skip()">Skip</button>
		<button *ngIf="!question.options.length" [disabled]="!response" (keyup.enter)="respond()" (click)="respond()">Submit</button>
	</div>
</ng-template>

<ng-template #freeResponse>
	<div>
		<input class="w-100" type="text" (keyup)="checkSubmit($event)" [(ngModel)]="response">
	</div>
</ng-template>
