import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-code-verify-input',
  templateUrl: './code-verify-input.component.html',
  styleUrls: ['./code-verify-input.component.scss']
})
export class CodeVerifyInputComponent implements OnInit {

	@Input() isSending: boolean;
	@Input() verifying: boolean;
	@Input() contact: string;
	@Input() err: string;
	@Input() resendInSec: number;
	@Output() cancel: EventEmitter<boolean> = new EventEmitter();
	@Output() submit: EventEmitter<string> = new EventEmitter();
	@Output() resend: EventEmitter<boolean> = new EventEmitter();

	code: string;

	
	constructor() { }

	ngOnInit(): void {

	}

	verifyCode() {
		this.submit.emit(this.code)
	}

	sendCode() {
		this.resend.emit(true)
	}

	goBack() {
		this.cancel.emit(true)
	}

}
