<div class="space-between w-100">
	<div class="team-content">
		<span class="team-menu-toggle">
			<svg (click)="toggleMenu()" width="2em" height="2em" viewBox="0 0 16 16"
				class="bi bi-arrow-bar-right push-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd"
					d="M10.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L12.793 8l-2.647-2.646a.5.5 0 0 1 0-.708z" />
				<path fill-rule="evenodd"
					d="M6 8a.5.5 0 0 1 .5-.5H13a.5.5 0 0 1 0 1H6.5A.5.5 0 0 1 6 8zm-2.5 6a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 1 0v11a.5.5 0 0 1-.5.5z" />
			</svg>
		</span>
		<div class="team-title">
			<img class="rounded-avatar" [src]="game?.my_team?.team_image" width="100" />
			<p>{{game?.my_team?.kt_team_id ? '#'+game.my_team.kt_team_id+' - ' : ''}}{{game?.my_team?.team_name}}</p>
		</div>
		<div *ngIf="game?.my_team?.kt_team_id">
			<p *ngIf="game?.me?.is_captain">YOU are the captain. Click on the star next to a player's name to pass the
				Captain status</p>
			<p *ngIf="!game?.me?.is_captain">{{captainsName().toUpperCase()}} is the captain.</p>
		</div>
		<div *ngIf="game?.my_team?.kt_team_id">
			<div class="team-member" *ngFor="let player of game?.my_team?.players">
				<div class="promote-captain">
					<svg *ngIf="player.is_captain" height='2em' width='2em' viewBox="10 10 198 198">
						<polygon points='100,10 130,60 190,78 150,125 160,198 100,165 40,198 50,135 10,78 70,60'
							style='fill:black;stroke:black;stroke-width:3;fill-rule:nonzero;' />
					</svg>
					<svg *ngIf="!player.playerid || (!game.me.is_captain && !player.is_captain)" height='2em'
						width='2em' viewBox="10 10 198 198">
						<path
							d='M 100,10 L 130,60 L 190,78 L 150,125 L 160,198 L 100,165 L 40,198 L 50,135 L 10,78 L 70,60 Z'
							style='fill:none;stroke:black;stroke-width:0;fill-rule:nonzero;' />
					</svg>
					<svg *ngIf="(!player.is_captain && game.me.is_captain) && player.playerid"
						(click)="toPromote = player" height='2em' width='2em' viewBox="10 10 198 198">
						<path
							d='M 100,10 L 130,60 L 190,78 L 150,125 L 160,198 L 100,165 L 40,198 L 50,135 L 10,78 L 70,60 Z'
							style='fill:none;stroke:black;stroke-width:8;fill-rule:nonzero;' />
					</svg>
				</div>
				<div class="player-info">
					<div class="player-name">{{player.player_code === game.me.player_code ? '(You)' : ' '}}
						{{player.player_name ? player.player_name : tempName(player)}} {{player.playerid ||
						player.client_id ? '' : '(invited)'}}</div>
				</div>
				<div class="cancel-invite" *ngIf="!player.playerid && !player.client_id && game.me.is_captain"
					(click)="cancelInvite(player)">+</div>
				<div *ngIf="player.player_code === game.me.player_code" (click)="playerKick = player">
					<div class="leave-game">Leave Game</div>
				</div>
				<div *ngIf="player.playerid && game.me.is_captain && player.playerid !== game.me.playerid"
					(click)="playerKick = player">
					<i class="material-icons drop-player">minimize</i>
				</div>
			</div>
		</div>
		<h2 *ngIf="!isLoggedIn" class="center-text">You're all alone... <span class="sim-link" (click)="toAuth()">Log
				In</span> to register your team and add your teammates!</h2>
		<h2 *ngIf="!game?.my_team?.kt_team_id && isLoggedIn" class="center-text">Add your teammates, track your scores,
			and get entered into League Play!</h2>
		<div *ngIf="!game?.my_team?.kt_team_id && isLoggedIn" class="vert-align-center">
			<button (click)="startUpgradeTeam()">Register Your Team</button>
			<p *ngIf="regPrompt" (click)="toggleMenu()" class="sim-link center-text lg-mt">Dismiss</p>
		</div>
	</div>

	<div *ngIf="!regPrompt" class="invite-controls">
		<p *ngIf="invErr" class="error-message">{{invErr}}</p>
		<div *ngIf="game?.my_team?.kt_team_id" class="invite-teammate" (click)="inviteTeammate()">
			<span *ngIf="!inviting && !dropping">Invite Teammate</span>
			<span *ngIf="inviting">Sending Invite...</span>
			<span *ngIf="dropping">Dropping Player...</span>
		</div>
		<div *ngIf="!game?.my_team?.kt_team_id" class="invite-teammate">
			<span (click)="playerKick = game?.me">Leave Game</span>
		</div>
	</div>
</div>

<div *ngIf="toPromote" class="modal-background">
	<div class="modal-container">
		<div class="modal-header">
			<h2>Make {{toPromote.player_name}} the captain?</h2>
			<i (click)="toPromote = null" class="material-icons">clear</i>
		</div>
		<div class="modal-footer">
			<button class="cancel-btn" (click)="toPromote = null">No</button>
			<button (click)="promoteCaptain(toPromote)">Yes</button>
		</div>
	</div>
</div>

<div *ngIf="playerKick" class="modal-background">
	<div class="modal-container">
		<div class="modal-header">
			<h2 *ngIf="playerKick.email !== game.me.email; else kickMe">Kick {{playerKick.player_name}} from the team?
			</h2>
			<ng-template #kickMe>
				<h2>Remove Yourself from the Game?</h2>
			</ng-template>
			<i (click)="playerKick = null" class="material-icons">clear</i>
		</div>
		<div class="modal-footer">
			<button class="cancel-btn" (click)="playerKick = null">No</button>
			<button (click)="dropPlayer(playerKick)">Yes</button>
		</div>
	</div>
</div>

<div *ngIf="registering" class="modal-background">
	<div class="modal-container">
		<div class="modal-header">
			<h2>Register your team!</h2>
			<i (click)="cancelUpgradeTeam()" class="material-icons">clear</i>
		</div>
		<div class="modal-content">
			<select #teamSelect (change)="setTeam(teamSelect.value)">
				<option style="display: none;"></option>
				<option value="">+ New Team</option>
				<option *ngFor="let t of teams" value="{{t.teamid}}">#{{t.kt_team_id}} - {{t.team_name}}</option>
			</select>
		</div>
		<div class="modal-footer">
			<button class="cancel-btn" (click)="cancelUpgradeTeam()">Cancel</button>
			<button *ngIf="chosenTeam" (click)="registerTeam()">Register</button>
		</div>
	</div>
</div>