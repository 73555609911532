import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dice-button',
  templateUrl: './dice-button.component.html',
  styleUrls: ['./dice-button.component.scss']
})
export class DiceButtonComponent implements OnInit {
  @Output() buttonClick: EventEmitter<void> = new EventEmitter()

  @ViewChild('diceSVG') svgElement: ElementRef;
  rotationAngle: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {

    this.rotationAngle += 45;
    this.svgElement.nativeElement.style.transform = `rotate(${this.rotationAngle}deg)`;
    this.buttonClick.emit()
  }

}
