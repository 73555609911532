<h1 mat-dialog-title>Invite a teammate</h1>
<div mat-dialog-content>
	<select class="w-100" (change)="switchType($event.target.value)">
		<option *ngIf="!data.noqr" value="qr" selected>Invite by QR code</option>
		<option value="email">Invite by Email</option>
		<option value="phone">Invite by Text</option>
	</select>

	<app-contact-chooser
		*ngIf="contactType === 'email' || contactType === 'phone'"
		[contactType]="contactType"
		[lockContactType]="contactType"
		[noSubmit]="true"
		(updateContact)="updateContact($event)"></app-contact-chooser>

	<div *ngIf="contactType === 'qr' && inviteLink && !loading" class="vert-align-center">
		<qrcode 
			[qrdata]="inviteLink"
			[width]="256"
			[errorCorrectionLevel]="'M'"
			[scale]="8"></qrcode>
		<h2 class="center-text">Have your teammates scan this code to join your team!</h2>
	</div>

	<h2 *ngIf="contactType === 'qr' && loading">Generating QR Code...</h2>
	<h3 *ngIf="err">{{err}}</h3>
</div>
<div mat-dialog-actions class="hrz-between">
  <button *ngIf="contactType !== 'qr'" class="modal-button" (click)="onNoClick()">Cancel</button>
  <button *ngIf="contactType !== 'qr'" class="modal-button" [disabled]="invalidContact()" [mat-dialog-close]="{email: email, phone: phone}" cdkFocusInitial>Send</button>
</div>


