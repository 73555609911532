import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TeamService } from '../services/team.service';
import { AuthService } from '../services/auth.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-my-teams',
  templateUrl: './my-teams.component.html',
  styleUrls: ['./my-teams.component.scss']
})
export class MyTeamsComponent implements OnInit {

	loading = true;
	err = "";
	teams: TeamRecord[] = [];
	player: Player;

	constructor(
		private teamServe: TeamService,
		private router: Router,
		private auth: AuthService) { }

	ngOnInit(): void {
		this.player = this.auth.getPlayer();
		this.getTeamList();
	}

	getTeamList() {
		this.teamServe.getTeamList()
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res:TeamRecord[]) => {
					res.sort((a, b) => {
						if (this.pendingInvite(b) && !this.pendingInvite(a)) return 1;
						else if (this.pendingInvite(a) && !this.pendingInvite(b)) return -1;
						else return 0;
					})
					this.teams = res
				},
				err => this.err = err.error.detail)
	}

	toRestore() {
		this.router.navigate(['/team/restore']);
	}

	pendingInvite(team: TeamRecord) {
		for (let m of team.members) {
			if (m.playerid === this.player.playerid && !m.joined) {
				return true;
			}
		}
		return false;
	}

	pendingRequest(team: TeamRecord) {
		for (let m of team.members) {
			if (m.playerid === this.player.playerid && !m.joined && !m.requested) {
				return true;
			}
		}
		return false;
	}

}
