<header mat-dialog-title class="mb0">
  <h1>Avatar Preview / Crop</h1>
</header>
<mat-dialog-content>
    <p *ngIf="isImageLoaded" class="mb10">Frame and crop your new avatar</p>
    <div class="cropper-wrapper">
      <div *ngIf="!isImageLoaded" class="avatar-spinner-wrapper">
        <div class="avatar-spinner-text">Uploading Image</div>
        <div class="avatar-spinner"></div>
      </div>
      <image-cropper
        [imageFile]="data.image"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1/1"
        [resizeToWidth]="100"
        [roundCropper]="true"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()">
      </image-cropper>
    </div>

  <!-- </dx-scroll-view> -->
</mat-dialog-content>

<mat-dialog-actions class="flex">
  <button class="modal-button" mat-dialog-close >Cancel</button>
  <button class="modal-button" (click)="save()">Save</button>
</mat-dialog-actions>
