import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

	rootUri = environment.root_uri;

	constructor(private http: HttpClient) { }

	getAds(game_id: number) {
		let params = {r: Math.random().toString()}
		return this.http.get(`${this.rootUri}/api/player/game/${game_id}/ads`)
	}
}
