<div class="flex flex-col w-100">
  <input
    id="team-name"
    type="text"
    class="team-name text-lg w-100"
    [maxlength]="maxLength"
    placeholder="Team Name"
    [(ngModel)]="model"
    (ngModelChange)="onModelChange($event)"

  />
  <label class="text-right text-sm text-muted" for="team-name">{{ charCount }}/{{ maxLength }}</label>
</div>
