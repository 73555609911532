import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModalComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  isImageLoaded : boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = this.data.image;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }

  imageLoaded() {

  }

  cropperReady() {
    this.isImageLoaded = true;
  }

  loadImageFailed() {
      // show message
  }


  save() {
    this.dialogRef.close(this.croppedImage);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
