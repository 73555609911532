import { Component, OnInit, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameService } from '../../services/game.service';
import { TeamService } from '../../services/team.service';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invite-teammate-modal',
  templateUrl: './invite-teammate-modal.component.html',
  styleUrls: ['./invite-teammate-modal.component.scss']
})
export class InviteTeammateModalComponent implements OnInit {

  @ViewChild('phoneIn') pInput: ElementRef

  email: string = "";
  phone: string = "";

  loading: boolean = true;

  inviteLink: string;
  err = ""

  contactType: 'email' | 'phone' | 'qr' = 'qr'

  constructor(
    public dialogRef: MatDialogRef<InviteTeammateModalComponent>,
    private gameServe: GameService,
    private teamServe: TeamService,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
  	if (this.contactType === 'qr') {
  		if (this.data.noqr) this.contactType = 'email';
  		else this.getQrLink();
  	}
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  updateContact(e: string) {
  	this[this.contactType] = e;
  }

  switchType(type: 'email' | 'phone' | 'qr') {
  	this.email = ""
  	this.phone = ""
  	this.err = ""
  	if (type === 'qr') {
  		if (this.data.noqr) this.contactType = 'email';
  		else this.getQrLink();
  	}
  	this.contactType = type;
  }

  getQrLink() {
  	this.loading = true;
  	if (this.data.game) this.gameInvite();
  	else {
  		this.err = "Unable to generate QR code"
  		this.loading = false;
  	}
  }

  gameInvite() {
  	this.gameServe.getInivteToken(this.data.game.gameid, this.data.game.my_team.team_code)
  		.pipe(finalize(() => this.loading = false))
  		.subscribe(
  			(res: {status: string, msg: string, urlid: string}) => {
  				this.inviteLink = `${environment.api_root}/short-url?urlid=${res.urlid}`
  				console.log(this.inviteLink)
  			},
  			err => this.err = err.error.detail)
  }

  invalidContact() {
  	return ((!this.email && !this.phone) || (this.contactType === 'email' && !this.validEmail()) || (this.contactType === 'phone' && !this.validPhone()))
  }

  validEmail(): boolean {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (this.email && this.email.length > 0 && re.test(String(this.email).toLowerCase()));
  }

  validPhone() {
  	return (this.phone && this.phone.length === 10);
  }
}
