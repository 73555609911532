import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LinkJoinComponent } from './link-join/link-join.component';
import { HomeComponent } from './home/home.component';
import { JoinComponent } from './join/join.component';
import { GameComponent } from './game/game.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthComponent } from './auth/auth.component';
import { TokenInviteComponent } from './token-invite/token-invite.component';
import { TeamPageComponent } from './team-page/team-page.component';
import { CreateTeamComponent } from './create-team/create-team.component';
import { MyTeamsComponent }  from './my-teams/my-teams.component';
import { TeamRestoreComponent } from './team-restore/team-restore.component';
import { ShortLinkComponent } from './short-link/short-link.component';
import { SurveysComponent } from './surveys/surveys.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'sl/:url_id', component: ShortLinkComponent},
  {path: 'join', component: JoinComponent},
  {path: 'team/list', component: MyTeamsComponent, canActivate: [AuthGuard]},
  {path: 'team/create', component: CreateTeamComponent},
  {path: 'team/restore', component: TeamRestoreComponent, canActivate: [AuthGuard]},
  {path: 'team/:t_id', component: TeamPageComponent},
  {path: 'auth/link/join/:id/team/:t_id', component: LinkJoinComponent},
  {path: 'auth/link/invite/:token', component: TokenInviteComponent},
  {path: 'game/:id', component: GameComponent },
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  {path: 'auth', component: AuthComponent },
  {path: 'survey', component: SurveysComponent },
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
