import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LinkService } from '../services/link.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-short-link',
  templateUrl: './short-link.component.html',
  styleUrls: ['./short-link.component.scss']
})
export class ShortLinkComponent implements OnInit {

	err = "";

	link: string;

	constructor(
		private route: ActivatedRoute,
		private linkServe: LinkService,
		private router: Router,
		private auth: AuthService) { }

	ngOnInit(): void {
		this.route.params
		.subscribe((params: any) => this.getLongUrl(params.url_id))
	}

	getLongUrl(shortUrl: string) {
		this.linkServe.getLongUrl(shortUrl)
			.subscribe(
				(res: {long_url: string}) => {
					console.log(res)
					let urlPrts = res.long_url.split("#");
					if (urlPrts[1]) {
						let tknPrts = urlPrts[1].split(/[=&]/g);
						for (let i = 0; i < tknPrts.length; i = i + 2) {
							if (tknPrts[i] == "token") {
								this.auth.setToken(tknPrts[i + 1]);
							}
						}
					}

          setTimeout(() => {
            window.location.href = urlPrts[0];
          }, 1000);
				},
				err => this.err = err.error.detail)
	}

}
