<div class="content-container">
  <div class="content">
    <div class="title">Take the Survey</div>
    <div class="ss-logo">
    	<a href="https://kingtrivia.com/surveyslam">
    		<img src="/assets/img/SurveySlamSimple.png">
    	</a>
    </div>
    <p>Survey Slam is the poll-based quiz game where teams compete to guess the most popular answers to our survey questions.</p>
    <p><strong>Help us build the game by sharing <em>your</em> opinion about the prompts below.</strong></p>
    <p>Then <a href="hhttps://kingtrivia.com/where-to-play?game=slam">play Survey Slam</a> and guess the most popular answers!</p>
    <app-survey-feed></app-survey-feed>
  </div>
</div>
