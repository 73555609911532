import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

	rootUri = environment.root_uri;

	constructor(private http: HttpClient) { }

	prepFile(file: File) {
		let data = new FormData();
		data.append('image', file);
		return data;
	}

	gameImages(gameid: string) {
		let params = {r: Math.random().toString()}
		return this.http.get(`${this.rootUri}/api/player/game/${gameid}/images`, {params});
	}

	uploadImage(context: string, id: string, image: File) {
		let uriContext = "player"
		if (context === 'game') uriContext = 'player/game';
		if (context === 'team') uriContext = "team";
		let headers = new HttpHeaders({'enctype': 'multipart/form-data'});
		return this.http.post(
			`${this.rootUri}/api/${uriContext}/${id}/image/upload`,
			this.prepFile(image),
			{ headers })
	}

	deleteImage(context: string, id: string, code: string) {
		let uriContext = "player"
		if (context === 'game') uriContext = 'player/game';
		if (context === 'team') uriContext = "team";
		return this.http.post(`${this.rootUri}/api/${uriContext}/${id}/image/${code}/delete`, {});
	}

}
