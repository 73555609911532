import { Component, OnInit, Input } from '@angular/core';
import { SurveyService } from '../services/survey.service';
import { AuthService } from '../services/auth.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-survey-feed',
  templateUrl: './survey-feed.component.html',
  styleUrls: ['./survey-feed.component.scss']
})
export class SurveyFeedComponent implements OnInit {

	@Input() previewMode: boolean;

	question: SurveyQuestion;
	loading = "One Moment..."
	err = ""
	response = ""

	minimumTime = 2000;

	maxDailyRes;

	currentRes = 1;

	previewed: boolean;

	resMsg = [
		"Finding your question...",
		"Just a sec...",
		"Eenie, meeny, minie, moe...",
		"Making something up...",
		"Beep Boop...",
		"Riddle me this!",
		"We value your opinion!",
		"Are you sure?",
		"Whatever floats your boat...",
		"Nothing we haven't heard before (probably)...",
		"Is that answer gluten free?",
		"Yeah, well, that's just, like, your opinion, man...",
		"99 survey slams on the wall, 99 surveys to slam...",
		"Filing that under unpopular opinions...",
		"Hey, we don't judge...",
		"Did we ask for your opinion? (oh wait, we did)",
		"Technically, there are no wrong opinions...",
		"I'm sure someone out there agrees with you...",
		"Thank you for your response!",
		"*Mic drop*",
		"You've got a wild imagination there...",
		"BAM!",
		"Sounds about right?",
		"That's a stamp on my bingo card.",
		"Tell the truth... we can handle it. (maybe)",
		"Telling it like it is."
	]

	skipMsg = [
		"Beep Boop...",
		"It was a stupid question anyway...",
		"Back to the drawing board...",
		"But wait, there's more!",
		"Eenie, meeny, minie, moe...",
		"Just a sec...",
		"Let's see what else we've got here...",
		"They can't all be winners, I guess...",
		"A person of discerning tastes, I see...",
		"Next up...",
		"Scratch that.",
		"If you don't like it, you can answer another one.",
		"Nothing broken. Just our ego.",
		"No opinion is still kind of an opinion... right?",
		"But that was my FAVORITE question!",
		"There's more where that came from!",
		"No comment, eh?",
		"Next time, just make something up!"
	]

	constructor(private survey: SurveyService, private auth: AuthService) { }

	ngOnInit(): void {
		if (this.previewMode) {
			this.checkPreviewed();
		}
		this.getResponseCounters();
		this.getQuestion();
	}

	getQuestion() {
		this.loading = (this.loading) ? this.loading : this.randomLoad(this.resMsg)
		this.err = ""
		this.survey.getSurveyQuestion()
			.pipe(finalize(() => this.loading = ""))
			.subscribe(
				(res: SurveyResult) => {
					if (!this.previewed) {
						if (!this.maxDailyRes) {
							this.maxDailyRes = res.count + this.currentRes - 1 >= 20 ? 20 : res.count + this.currentRes - 1;
							localStorage.setItem(`${this.getCounterSlug()}-max`, this.maxDailyRes.toString())
						}
						else if (res.count + this.currentRes < this.maxDailyRes - this.currentRes) {
							this.maxDailyRes = this.currentRes - 1 + res.count;
						}
						if (this.currentRes <= this.maxDailyRes) this.question = res.survey;
						this.response = "";
					}
				},
				err => this.err = err.error.detail)
	}

	respond(response: string = "") {
		if (response) this.response = response;
		if (!this.response) return;
		this.loading = (this.loading) ? this.loading : this.randomLoad(this.resMsg)
		this.err = ""
		const startTimestamp = new Date().getTime();
		this.survey.surveyResponse(this.question.surveyid, this.response)
			.pipe(finalize(() => this.unloadAfter(startTimestamp)))
			.subscribe(
				(res: SurveyResult) => {
					let resNum = this.incrementResponseCounter()
					if (this.previewMode) this.finishPreview();
					else {
						this.question = resNum > this.maxDailyRes ? null : res.survey
						this.response = ""
					}
				},
				err => this.err = err.error.detail)
	}

	finishPreview() {
		this.previewed = true;
		let key = `${this.getCounterSlug()}-previewed`
		localStorage.setItem(key, "true");
		this.question = null;
	}

	checkPreviewed() {
		let key = `${this.getCounterSlug()}-previewed`
		let previewed = localStorage.getItem(key);
		this.previewed = previewed ? true : false;
	}

	getCounterSlug() {
		let player = this.auth.getPlayer()
		let pid = player ? `${player.playerid}-` : '';
		let dt = new Date();
		return `${pid}res-${dt.getUTCFullYear()}-${dt.getUTCMonth() + 1}-${dt.getUTCDate()}`;
	}

	getResponseCounters() {
		let dtSlug = this.getCounterSlug()
		let responseNum = localStorage.getItem(dtSlug)
		let maxNum = localStorage.getItem(`${dtSlug}-max`)
		if (!responseNum) {
			localStorage.setItem(dtSlug, '1')
			responseNum = '1'
		}
		if (maxNum) this.maxDailyRes = parseInt(maxNum, 10);
		this.currentRes = parseInt(responseNum, 10);
		return this.currentRes;
	}

	incrementResponseCounter() {
		let dtSlug = this.getCounterSlug();
		let responseNum = localStorage.getItem(dtSlug)
		let newResNum = 1
		if (responseNum) newResNum = parseInt(responseNum, 10) + 1;
		localStorage.setItem(dtSlug, newResNum.toString());
		this.currentRes = newResNum;
		return newResNum;
	}

	skip(reason: string = "No Reason") {
		this.loading = (this.loading) ? this.loading : this.randomLoad(this.skipMsg)
		this.err = ""
		const startTimestamp = new Date().getTime();
		this.survey.surveySkip(this.question.surveyid, reason)
			.pipe(finalize(() => this.unloadAfter(startTimestamp)))
			.subscribe(
				(res: SurveyResult) => {
					let resNum = this.incrementResponseCounter()
					this.question = resNum > this.maxDailyRes ? null : res.survey
					this.response = ""
				},
				err => this.err = err.error.detail)
	}

	unloadAfter(startTime: number) {
		const elapsed = new Date().getTime() - startTime;
		if (elapsed < this.minimumTime) {
			let time = this.minimumTime - elapsed
			setTimeout(function(){ this.loading = ""}.bind(this), time)
		}
		else this.loading = "";
	}

	randomLoad(messageList: string[]) {
		let max = messageList.length;
		return messageList[Math.floor(Math.random() * max)];
	}

	checkSubmit(e: any) {
		if (e.which === 13) {
			if (this.response) {
				this.respond();
			}
		}
	}

}
