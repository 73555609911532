import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { InviteTeammateModalComponent } from '../modals/invite-teammate-modal/invite-teammate-modal.component';
import { TeamService } from '../services/team.service';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
	selector: 'app-team-page',
	templateUrl: './team-page.component.html',
	styleUrls: ['./team-page.component.scss']
})
export class TeamPageComponent implements OnInit {

	@ViewChild('inputFile') inputFile: ElementRef<HTMLElement>
	@ViewChild('photoPreview') photoPreview: ElementRef<HTMLElement>

	team_id: string;
	loading = true;
	editing: boolean;
	team: TeamRecord;
	editName: string;
	editSlogan: string;
	restrictEdit: boolean;
	noRequests: boolean;
	err = ""
	player: Player
	action = "";
	showMenu: boolean;
	targetPlayer: TeamMember;

	uploadMessage: string = ''
	uploading: boolean = false
	imageChangedEvent: any = ''
	croppedImage: any = ''
	showPreview: boolean = false
	imageToCropLoaded: boolean = false

	constructor(
		public dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute,
		private teamServe: TeamService,
		private authServe: AuthService,
	) { }

	ngOnInit(): void {
		this.route.params
			.subscribe((params) => {
				this.team_id = params.t_id;
				this.getTeamData()
			})
		this.player = this.authServe.getPlayer()

	}

	decodeBase64(base64String) {
		var binaryString = window.atob(base64String);
		var len = binaryString.length;
		var bytes = new Uint8Array(len);

		for (var i = 0; i < len; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}

		return bytes;
	}

	// Convert Base64 to File
	base64ToFile(base64String, filename, mimeType) {
		var decodedBytes = this.decodeBase64(base64String);
		var file = new File([decodedBytes], filename, { type: mimeType });
		return file;
	}

	chooseFile() {
		this.inputFile.nativeElement.click()
	}

	onFileSelected(event: any): void {
		this.imageChangedEvent = event
	}
	onShowPreview() {
		this.showPreview = !this.showPreview
		setTimeout(() => {
			if (this.showPreview) {
				this.photoPreview.nativeElement.style.backgroundImage = `url("${this.croppedImage}")`
			}
		}, 500);
	}
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64
		if (this.showPreview) {
			this.photoPreview.nativeElement.style.backgroundImage = `url("${this.croppedImage}")`
		}
	}
	imageLoaded(image: LoadedImage) {
		this.imageToCropLoaded = true
		this.croppedImage = image.transformed.base64
	}
	cropperReady() {
	}
	loadImageFailed() {
		// show message
	}

	async onUpload(): Promise<void> {
		if (!this.croppedImage) {
			this.uploadMessage = 'No file selected!'
			return
		}
		let formData = new FormData()
		var imageFile = await fetch(this.croppedImage)
		const blob = await imageFile.blob()

		formData.append('image', blob)
		this.uploading = true
		this.teamServe.updateTeamPhoto(this.team.teamid, formData).subscribe((result: Object) => {
			this.uploading = false
			this.action = ''
			if (result.hasOwnProperty('url')) {

				this.team.team_image = this.croppedImage
				this.resetPhotoUpload()
			} else {
				this.uploadMessage = "An error occured. Please try again later."
			}

		}, err => {
			this.uploading = false
			this.uploadMessage = "An error occured. Please try again later."

		})


	}

	resetPhotoUpload() {
		this.imageChangedEvent = null
		this.showPreview = false
		this.imageToCropLoaded = false
		this.croppedImage = null
	}

	toAuth() {
		let params: any = { previous: window.location.pathname }
		this.router.navigate(['/auth'], { queryParams: params });
	}

	getTeamData() {
		this.teamServe.getTeamDetail(this.team_id)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: TeamRecord) => this.team = res,
				err => this.err = err.error.detail)
	}

	startEdit() {
		this.err = ""
		this.editName = this.team.team_name;
		this.editSlogan = this.team.team_slogan;
		this.restrictEdit = this.team.restrict_editing;
		this.noRequests = this.team.no_requests;
	}

	cancelEdit() {
		this.err = ""
		this.editName = ""
		this.editSlogan = ""
		this.action = '';
	}

	saveTeam() {
		if (!this.editName) return;
		this.err = ""
		this.loading = true;
		this.teamServe.editTeam(
			this.team.teamid,
			this.editName,
			this.editSlogan,
			this.restrictEdit,
			this.noRequests)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: TeamRecord) => {
					this.team = res;
					this.cancelEdit()
				},
				err => this.err = err.error.detail)
	}

	allowedEdit() {
		if (!this.player) return false;
		if (this.team.restrict_editing) {
			if (this.player.playerid === this.team.owner.playerid) {
				return true
			}
		}
		else {
			for (let m of this.team.members) {
				if (this.player.playerid === m.playerid && m.joined) return true;
			}
		}
		return false;
	}

	pendingInvite(player: TeamMember | Player = this.player) {
		if (player) {
			if (player.hasOwnProperty('joined')) {
				let tm: TeamMember = player;
				if (!tm.joined && !tm.requested) return true;
			}
			else {
				for (let m of this.team.members) {
					if (m.playerid === player?.playerid && !m.joined && !m.requested) return true;
				}
			}
		}
		return false;
	}

	pendingRequest(player: TeamMember | Player = this.player) {
		if (player) {
			if (player.hasOwnProperty('joined')) {
				let tm: TeamMember = player;
				if (!tm.joined && tm.requested) return true;
			}
			else {
				for (let m of this.team.members) {
					if (m.playerid === player?.playerid && !m.joined && m.requested) return true;
				}
			}
		}
		return false;
	}

	eligibleRequest() {
		if (this.player) {
			for (let m of this.team.members) {
				if (m.playerid === this.player.playerid) return false;
			}
			return true;
		}
		return false;
	}

	startRemove(player: TeamMember) {
		this.err = ""
		this.targetPlayer = player;
		this.action = 'removePlayer';
	}

	startReview(player: TeamMember) {
		this.err = ""
		this.targetPlayer = player;
		this.action = 'reviewRequest'
	}

	startAction(action: string) {
		this.err = "";
		if (action === 'editTeam') this.startEdit();
		if (action === 'newOwner') this.targetPlayer = this.team.owner
		this.action = action;
		this.showMenu = false;
	}

	ownerSelect(owner: string) {
		for (let m of this.team.members) {
			if (m.playerid === owner) {
				this.targetPlayer = m;
				break;
			}
		}
	}

	setOwner() {
		if (this.targetPlayer.playerid === this.team.owner.playerid) {
			this.action = "";
			return;
		}
		this.loading = true;
		this.action = "";
		this.teamServe.setOwner(this.team.teamid, this.targetPlayer.playerid)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: TeamRecord) => this.team = res,
				err => this.err = err.error.detail)
	}

	declineInvite() {
		this.targetPlayer = this.player;
		this.removePlayer();
	}

	acceptInvite() {
		this.targetPlayer = this.player
		this.approvePlayer()
	}

	approvePlayer() {
		this.err = "";
		this.loading = true;
		this.action = "";
		this.teamServe.acceptInvite(this.team.teamid, this.targetPlayer.playerid)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: TeamRecord) => this.team = res,
				err => this.err = err.error.detail)
	}

	removePlayer() {
		this.err = "";
		this.loading = true;
		this.action = "";
		this.teamServe.removePlayer(this.team.teamid, this.targetPlayer.playerid)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: TeamRecord) => this.team = res,
				err => this.err = err.error.detail)
	}

	leaveTeam() {
		this.targetPlayer = this.player;
		this.removePlayer();
	}

	disbandTeam() {
		this.err = "";
		this.loading = true;
		this.action = "";
		this.teamServe.disband(this.team.teamid)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: TeamRecord) => this.team = res,
				err => this.err = err.error.detail)
	}

	requestJoin() {
		this.err = "";
		this.loading = true;
		this.action = "";
		this.teamServe.requestJoin(this.team.teamid)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: TeamRecord) => this.team = res,
				err => this.err = err.error.detail)
	}

	startInvite() {
		if (this.loading) return;

		const dialogRef = this.dialog.open(InviteTeammateModalComponent, {
			width: '350px',
			data: { noqr: true }
		});

		dialogRef.afterClosed().subscribe((data: { 'email': string, 'phone': string }) => {
			if (data) {
				this.loading = true;
				this.teamServe.invitePlayer(this.team.teamid, data.email, data.phone)
					.pipe(finalize(() => this.loading = false))
					.subscribe(
						(res: TeamRecord) => this.team = res,
						err => this.err = err.error.detail)
			}
		});
	}

}