import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AdsService } from '../services/ads.service';
import { Ad, Game } from '../classes/Game';

@Component({
	selector: 'app-ads-carousel',
	templateUrl: './ads-carousel.component.html',
	styleUrls: ['./ads-carousel.component.scss']
})
export class AdsCarouselComponent implements OnInit, OnDestroy {

	@Input() game: Game;

	ads: Ad[] = [];
	currentAd: Ad;
	num_rounds = 0;
	ad_idx = 0;
	ad_switcher;

	constructor(private adServe: AdsService) { }

	ngOnInit(): void {
		this.getAds();
		this.getNumRounds();
	}

	ngOnDestroy() {
		clearInterval(this.ad_switcher);
	}

	getAds() {

		// don't fetch ads if event is private
		if (this.game.private_event) return

		this.adServe.getAds(this.game.kt_id)
			.subscribe(
				(res: Ad[]) => {
					this.ads = res;
					this.setCurrentAd();
				},
				err => console.log(err))
	}

	getNumRounds() {
		let sessionRounds = sessionStorage.getItem(`game_${this.game.gameid}_rounds`);
		if (sessionRounds) {
			let rounds = JSON.parse(sessionRounds);
			this.num_rounds = Object.keys(rounds).length;
		}
		console.log(this.num_rounds);
	}

	setCurrentAd() {
		if (!this.ads.length) return;
		this.ad_idx = this.num_rounds;
		while (this.ad_idx >= this.ads.length) {
			this.ad_idx -= this.ads.length;
		}
		this.currentAd = this.ads[this.ad_idx];
		if (this.ads.length > 1) {
			this.ad_switcher = setInterval(this.nextAd.bind(this), 30000);
		}
	}

	nextAd() {
		this.ad_idx++;
		if (this.ad_idx >= this.ads.length) this.ad_idx = 0;
		this.currentAd = this.ads[this.ad_idx];
	}

}
