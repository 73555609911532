import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Game } from '../classes/Game';

@Component({
  selector: 'app-game-image-manager',
  templateUrl: './game-image-manager.component.html',
  styleUrls: ['./game-image-manager.component.scss']
})
export class GameImageManagerComponent implements OnInit {

	@Input() game: Game;
	@Output() close: EventEmitter<boolean> = new EventEmitter();

	constructor() { }

	ngOnInit(): void {

	}

	hide() {
		this.close.emit()
	}

}
