import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-contact-chooser',
  templateUrl: './contact-chooser.component.html',
  styleUrls: ['./contact-chooser.component.scss']
})
export class ContactChooserComponent implements OnInit {

	@Input() err: string;
	@Input() contactType: 'email' | 'phone'
	@Input() needName: boolean;
	@Input() loading: boolean;
	@Input() invalidInput: boolean;
	@Input() lockContactType: 'email' | 'phone';
	@Input() noSubmit: boolean;
	@Output() typeSwitch: EventEmitter<string> = new EventEmitter();
	@Output() updateContact: EventEmitter<string> = new EventEmitter();
	@Output() updateName: EventEmitter<string> = new EventEmitter();
	@Output() submit: EventEmitter<boolean> = new EventEmitter();
	@Output() reset: EventEmitter<boolean> = new EventEmitter();
	@ViewChild("phoneIn") pInput: ElementRef

	email = "";
	phone = "";
	name = "";

	constructor() { }

	ngOnInit(): void {

	}

	inputPhone(phone) {
		this.stripPhone(phone)
		this.pInput.nativeElement.value = this.displayPhone()
		this.updateContact.emit(this.phone)
	}

	inputEmail(email) {
		this.email = email;
		this.updateContact.emit(this.email)
	}

	inputName(name) {
		this.name = name;
		this.updateName.emit(this.name)
	}

	stripPhone(phone: string) {
		this.phone = phone.replace(/[^\d]/g, "");
	}

	displayPhone() {
		let out = "";
		if (this.phone.length) {
			out = `(${this.phone.substring(0,3)}`
			if (this.phone.length > 3) {
				out = `${out}) ${this.phone.substring(3,6)}`
				if (this.phone.length > 6) {
					out = `${out}-${this.phone.substring(6)}`
				}
			}
		}
		return out
	}

	switchType(type: 'email' | 'phone') {
		if (type !== this.contactType) {
			this.email = ""
			this.phone = ""
			this.name = ""
			this.typeSwitch.emit(type)
		}
	}

	sendSubmit() {
		this.submit.emit(true);
	}

}
