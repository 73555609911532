<div class="content-container">
	<div class="content">
		<h1 class="center-text page-title">Your Teams</h1>
		<div *ngIf="!loading; else loadMsg">
			<div>
				<app-team-stub *ngFor="let t of teams" [team]="t" [isInvited]="pendingInvite(t)" [isRequested]="pendingRequest(t)"></app-team-stub>
				<div class="info-alert">
					<h3>{{!teams.length ? 'No Teams Found. ' : ''}}Missing a team? <span class="sim-link" (click)="toRestore()">Search the achives</span>!</h3>
				</div>
				<div class="mobile-spacer"></div>
			</div>
		</div>
	</div>
</div>

<ng-template #loadMsg>
	<h2 class="center-text">Finding your Teams...</h2>
</ng-template>