import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GameService } from '../services/game.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  games: GameStub[];
  err = ""

  constructor(
    private gameService: GameService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getGames();
  }

  getGames() {
  	  this.gameService.getCurrentGames()
    	.subscribe((res: GameStub[]) => {
    		if (res.length) {
    			this.games = res;
    		}
    		else this.router.navigate(['join']);
    	})
  }

  joinGame(): void {
    this.router.navigate(['join']);
    // this.ws.send(JSON.stringify({type: 'JOIN', game_id: '123'}));
  }

  joinExisting(game: any): void {
    this.err = ""
    this.gameService.joinGameById(game.gameid)
    .subscribe(
      joinResult => {
        console.log(joinResult);
        this.router.navigate(['game', joinResult.game_id]);
      },
      err => {
        if (err.error.status_code === 401) {
          localStorage.removeItem("auth_token");
          this.router.navigate(['/auth']);
        }
        else this.err = err.error.detail
      });

  }

  linkJoin(game: GameStub) {
  	this.router.navigate([`/auth/link/join/${game.gameid}/team/${game.team.team_code}`])
  }

  declineInvite(game: GameStub) {
  	this.err = ""
  	game.loading = true;
  	this.gameService.declineInvite(game.gameid, game.team.team_code)
  		.pipe(finalize(() => {
  			game.loading = false;
  			this.getGames();
  		}))
  		.subscribe(
  			res => game.declined = true,
  			err => this.err = err.error.detail)
  }

}
