<div class="content-container">
	<div class="content">
		<h1 class="no-mb">Team Archives</h1>
		<h2 class="no-mt">Lose Your Team? Find it here!</h2>
		<p>Enter your team number below:</p>
		<div *ngIf="!loading && !result" class="hrz-center">
			<input class="team-id-input" type="number" placeholder="Team #" [(ngModel)]="searchid">
			<button (click)="findTeam()">Search</button>
		</div>
		<p *ngIf="err" class="error-message">{{err}}</p>
		<div *ngIf="!loading && result">
			<h2 *ngIf="!result.success">Couldn't find a team with that number. Please double check your team number and try again.</h2>
			<div *ngIf="result.success">
				<h2>Team #{{searchid}} Found!</h2>
				<h3 *ngIf="result.on_team">
					<span *ngIf="!result.requested && !result.invited">You are on this team and it should appear in your <span class="sim-link" (click)="toTeams()">team list</span>.</span>
					<span *ngIf="result.invited">You are invited to this team. <span class="sim-link" (click)="toTeamPage(searchid)">Visit the team's page</span> to accept the invite.</span>
					<span *ngIf="result.requested">You requested to join this team, but the request is not yet approved. Ask one of your teammates to approve the request!</span>
				</h3>
				<h3 *ngIf="!result.on_team">You are NOT listed on this team. <span class="sim-link" (click)="toTeamPage(searchid)">Visit the team's page</span> to request access.</h3>
			</div>
			<div class="hrz-between">
				<button class="sm-mx" (click)="reset()">Start Over</button>
				<button *ngIf="result.success" class="sm-mx highlight-btn" (click)="toTeamPage(searchid)">View Team</button>
			</div>
		</div>
		<h2 *ngIf="loading" class="center-text">Looking for your team...</h2>
	</div>
</div>
