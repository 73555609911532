<div class="contact-box">
	<div *ngIf="!lockContactType" class="contact-type" [ngClass]="{'select-email': contactType === 'email', 'select-phone': contactType === 'phone'}">
		<div class="clickable" (click)="switchType('email')">
			<i class="material-icons" [ngClass]="contactType === 'phone' ? 'deemphasized' : ''">email</i>
		</div>
		<div class="clickable" (click)="switchType('phone')">
			<i class="material-icons" [ngClass]="contactType === 'email' ? 'deemphasized' : ''">phone</i>
		</div>
	</div>
	<div *ngIf="contactType">
		<div class="contact-inputs">
	    	<input 
	    		*ngIf="contactType === 'email'" 
	    		type="email" 
	    		placeholder="you@domain.com" 
	    		[ngClass]="invalidInput ? 'invalid' : ''"
	    		(input)="inputEmail($event.target.value)"
	    		[value]="email">
	    	<input 
	    		*ngIf="contactType === 'phone'" 
	    		#phoneIn 
	    		type="tel" 
	    		placeholder="(XXX) XXX-XXXX" 
	    		[ngClass]="invalidInput ? 'invalid' : ''"
	    		(input)="inputPhone($event.target.value)">
	    </div>
	    <div *ngIf="needName">
	    	<div class="hrz-center">
	    		<label>First time? Please choose a display name:</label>
	    	</div>
	    	<div class="hrz-center">
	    		<input type="text" (input)="inputName($event.target.value)" [value]="name" placeholder="Your Name">
	    	</div>
	    </div>
	    <div *ngIf="!loading && !noSubmit">
	    	<div *ngIf="err" class="error-message">{{err}}</div>
	    	<div class="hrz-center" class="register-box">
				<button *ngIf="!needName" class="sm-mb w-100" [disabled]="invalidInput || (contactType === 'email' && !email) || (contactType === 'phone' && !phone)" (click)="sendSubmit()">Send Code</button>
				<div *ngIf="needName">
					<button class="sm-mb w-100" [disabled]="!name" (click)="sendSubmit()">Create New Account</button>
					<p class="small center-text">By creating a player account you indicate that you have read, understand, and agree to the <a href="https://kingtrivia.com/king-trivia-privacy-policy/" target="_blank">kingtriva.com Terms of Use</a> and consent to receive communications from King Trivia.</p>
				</div>
			</div>
	    </div>
	    <h3 *ngIf="loading" class="center-text">One Moment...</h3>
	</div>
</div>
