import { ElementRef, Injectable, ViewChild } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SharedService {

  teamNames: string[] = [
    "The Water Coolers",
    "The Scanners",
    "Boss Mode",
    "Never Overtime",
    "We Break for No-one",
    "Desk Champions",
    "Office Heroes",
    "Not Your Ordinary Desk Jockeys",
    "Better Nine Than Five",
    "All Play and No Work",
    "The Squad",
    "Calculated Victory",
    "Fancy Freelancers",
    "Entrepre-new-world",
    "Team Caffeine",
    "Space Bar Cowboys",
    "Rebooters",
    "Sells Like Team Spirit",
    "Employees of the Moment",
    "Can I See Your Dog on Zoom?",
    "Just Here For Cake",
    "Zombeat Us If You Can",
    "Promotion Commotion",
    "Risky Biscuits",
    "Ain't Nothing But a Work Crew",
    "Weekend Warriors",
    "We Are The Champions",
    "We Will Smash You",
    "Forever Won",
    "Toon Squad",
    "Win-Win Situation",
    "Old School Cool",
    "All For Won",
    "Won Plus Won = Two",
    "Al Capowned",
    "Silence of the Yams",
    "Chubby Squonks",
    "Hot Dog Isn't a Sandwich",
    "Let's Get Ready To Crumble",
    "Downton Stabbey",
    "No Lucks Given",
    "Agatha Crispy",
    "Baking Bad",
    "Glitchy Biotches",
    "Ambitious Bishes",
    "Slack Attack",
    "Mission Implausible",
    "Our Team Name is Invisible",
    "It's My First Day",
    "It's Friday Somewhere",
    "We Tried",
    "We Deserve a Bonus",
    "Masters of the Universe",
    "Six-million-dollar Team",
    "Best or Bust",
    "Miracle Workers",
    "The Big Leagues",
    "Legends To Be",
    "The Future",
    "The Deciders",
    "Ace in the Hole",
    "Hail Mary",
    "The Underdogs",
    "Kingmakers",
    "The Titan Tribe",
    "Office Avengers",
    "The Chosen Ones",
    "The Writers of History",
    "Star Players",
    "No Risk, No Reward",
    "Dupin it Out",
    "The Hardy Boys",
    "Nancy Drew Plus Two",
    "A Slice of Life",
    "Tick Tock, You're Dead",
    "Rad Nose Reindeer",
    "Quizmas",
    "Ho-ho-heroes",
    "You Owe Me a Coke",
    "Mummy-Mia",
    "We will block you",
  ];

  generated: string[] = [];
  constructor() {}


  coolNames() {
    if (this.generated.length >= this.teamNames.length) this.generated = [];
    let selected =  this.teamNames[Math.floor(Math.random() * this.teamNames.length)];
    if (this.generated.indexOf(selected) < 0) {
      this.generated.push(selected);
      // reset
      if( this.generated.length === this.teamNames.length ){
        this.generated = [];
      }

    }
    else {
      selected = this.coolNames();
    }

    return selected;
  }

  generateTeamName(): string {
    const adjectives = [
      "Fierce",
      "Swift",
      "Mighty",
      "Brave",
      "Savage",
      "Electric",
      "Dynamic",
      "Eternal",
      "Radiant",
      "Blazing",
      "Fearless",
      "Victorious",
      "Roaring",
      "Ferocious",
      "Charming",
      "Valiant",
      "Mad",
      "Daring",
      "Majestic",
      "Unstoppable",
      "Paradoxical",
      "Flaming",
      "Frozen",
      "Thirsty",
      "Hungry",
      "Able-bodied",
      "Grateful",
      "Performative",
      "Loud",
      "Angry",
      "Sad",
      "Irritable",
      "Creative",
      "Creepy",
      "Coordinated",
      "Angelic",
      "Arable",
      "Atomic",
      "Bombastic",
      "Daring",
      "Dark",
      "Antideluvian",
      "Esteemed",
      "Eligible",
      "Fake",
      "Fascinating",
      "Fortunate",
      "Flippant",
      "Fabled",
      "Green",
      "Gastly",
      "Greedy",
      "Heavenly",
      "Hellish",
      "Horrible",
      "Hasty",
      "Inimitable",
      "Intermittent",
      "Inclusive",
      "Jolly",
      "Joyful",
      "Lasting",
      "Laborious",
      "Mental",
      "Messy",
      "Naughty",
      "Normal",
      "Nice",
      "Original",
      "Oblong",
      "Old",
      "Odd",
      "Powerful",
      "Potent",
      "Picky",
      "Pint-sized",
      "Quaint",
      "Quality",
      "Unfinished",
      "Rested",
      "Risky",
      "Restored",
      "Rambling",
      "Rolling",
      "Stoney",
      "Striped",
      "Storied",
      "Torrid",
      "Trembling",
      "Tasty",
      "Toasted",
      "Torrential",
      "Varied",
      "Wasted",
      "Watery",
      "Wiggling",
      "Yelling",
      "Yodelling",
      "Zooming",
      "Demonic",
      "Thundering"
    ];

    // Determine if using premade name (return if true)
    if (Math.random() < 0.2) return this.coolNames();
    // Determine if using plural nouns
    const plural = Math.random() < 0.5;
    // Determine if useThe or useAnd and if useAdj
    const useAnd = Math.random() < 0.2;
    const useThe = useAnd ? false : Math.random() < 0.3;
    const useAdj = useAnd ? false : !useThe ? true : Math.random() < 0.6
    // Set noun and renoun
    const noun = this.pickNoun(plural)
    const adjective = useAdj ? `${adjectives[Math.floor(Math.random() * adjectives.length)]} ` : "";

    let teamName = `${adjective}${noun}`;
    if (useThe) teamName = `The ${teamName}`;
    if (useAnd) {
      const renoun = this.pickNoun(plural);
      teamName = `${noun} and ${renoun}`;
    }
    return teamName;
  }

  pickNoun(plural: boolean = false) {
    const pluralNouns = [
      "Wolves",
      "Fairies",
      "Geniuses",
      "Bushes",
      "Ashes",
      "Echoes",
      "Paradoxes",
      "Mongooses",
      "Sheep"
    ];
    const singleNouns = [
      "Wolf",
      "Phoenix",
      "Fairy",
      "Fam",
      "Genius",
      "Gang",
      "Bush",
      "Ash",
      "Echo",
      "Paradox",
      "Mongoose",
      "Blood",
      "Thunder",
      "Sheep",
      "Fiction",
      "Toast"
    ];
    const standardNouns = [
      "Dragon",
      "Tart",
      "Sword",
      "Tiger",
      "Lion",
      "Wolf",
      "King",
      "Achiever",
      "Gladiator",
      "Warrior",
      "Falcon",
      "Panther",
      "Queen",
      "Vampire",
      "Spartan",
      "Pusher",
      "Nerd",
      "Axe",
      "Beaver",
      "Brat",
      "Millenial",
      "Zoomer",
      "Pilot",
      "Cage",
      "Angle",
      "Angel",
      "Programmer",
      "Ace",
      "Mastermind",
      "Champ",
      "Champion",
      "Maverick",
      "Star",
      "Titan",
      "Pioneer",
      "Quizzer",
      "Contender",
      "Puzzler",
      "Legend",
      "Fact",
      "Opinion",
      "Ember",
      "Cipher",
      "Pulse",
      "Mirage",
      "Paragon",
      "Email",
      "Car",
      "Boat",
      "Train",
      "Plane",
      "Finder",
      "Keeper",
      "Light",
      "Taco",
      "Burger",
      "Dog",
      "Cat",
      "Baker",
      "Maker",
      "Stone",
      "Coal",
      "Tree",
      "Rose",
      "Flower"
    ];

    let numStandard = standardNouns.length;
    let numExtra = plural ? pluralNouns.length : singleNouns.length;
    const nounExtra = plural ? pluralNouns : singleNouns;
    const nounIndex = Math.floor(Math.random() * numStandard + numExtra);
    if (nounIndex > numStandard) {
      return nounExtra[nounIndex - numStandard];
    }
    else if (plural) return `${standardNouns[nounIndex]}s`;
    else return standardNouns[nounIndex];
  }
}
