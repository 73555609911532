import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TeamService {

	rootUri = environment.root_uri;

	constructor(private http: HttpClient) { }

	getTeamDetail(kt_team_id: string) {
		return this.http.get(`${this.rootUri}/api/team/${kt_team_id}/detail`)
	}

	editTeam(teamid: string, team_name: string, team_slogan: string, restrict_editing: boolean, no_requests: boolean) {
		return this.http.post(
			`${this.rootUri}/api/team/${teamid}/edit`, { team_name, team_slogan, restrict_editing, no_requests });
	}

	createTeam(team_name, team_slogan) {
		return this.http.post(`${this.rootUri}/api/team/new`, { team_name, team_slogan });
	}

	getTeamList(joined_only: boolean = false) {
		return this.http.get(`${this.rootUri}/api/team/list?rnd=${Math.random()}${joined_only ? '&joined=yes' : ''}`);
	}

	setOwner(teamid: string, new_owner: string) {
		return this.http.post(`${this.rootUri}/api/team/${teamid}/set-owner`, { new_owner })
	}

	removePlayer(teamid: string, playerid: string) {
		return this.http.post(`${this.rootUri}/api/team/${teamid}/members/${playerid}/remove`, {})
	}

	invitePlayer(teamid: string, email: string, phone: string, playerid: string = "") {
		return this.http.post(`${this.rootUri}/api/team/${teamid}/members/invite`, { email, phone, playerid })
	}

	acceptInvite(teamid: string, playerid: string) {
		return this.http.post(`${this.rootUri}/api/team/${teamid}/members/${playerid}/approve-invite`, {})
	}

	requestJoin(teamid: string) {
		return this.http.post(`${this.rootUri}/api/team/${teamid}/members/request`, {});
	}

	disband(teamid: string) {
		return this.http.post(`${this.rootUri}/api/team/${teamid}/disband`, {});
	}

	restoreTeam(kt_team_id: string) {
		return this.http.post(`${this.rootUri}/api/team/${kt_team_id}/restore`, {})
	}

	updateTeamPhoto(teamid: string, formData: FormData) {
		return this.http.post(`${this.rootUri}/api/team/${teamid}/update-team-photo`, formData);
	}
}
