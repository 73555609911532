import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HeaderComponent } from './header/header.component';
import { GameComponent } from './game/game.component';
import { ProfileComponent } from './profile/profile.component';
import { MatIconModule } from '@angular/material/icon';
import { JoinComponent } from './join/join.component';
import { HomeComponent } from './home/home.component';
import { FormsModule } from '@angular/forms';
import { AuthComponent } from './auth/auth.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TokenInterceptor } from './auth/token.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { InviteTeammateModalComponent } from './modals/invite-teammate-modal/invite-teammate-modal.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { EnterNameComponent } from './modals/enter-name/enter-name.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AvatarModule } from "ngx-avatar";
import { ImageCropperModalComponent } from './profile/image-cropper-modal/image-cropper-modal.component';
import { LinkJoinComponent } from './link-join/link-join.component';
import { AdsCarouselComponent } from './ads-carousel/ads-carousel.component';
import { PastAnswersComponent } from './past-answers/past-answers.component';
import { AuthWidgetComponent } from './auth-widget/auth-widget.component';
import { ContactChooserComponent } from './contact-chooser/contact-chooser.component';
import { CodeVerifyInputComponent } from './code-verify-input/code-verify-input.component';
import { GameTeamManagerComponent } from './game-team-manager/game-team-manager.component';
import { TokenInviteComponent } from './token-invite/token-invite.component';
import { TeamPageComponent } from './team-page/team-page.component';
import { CreateTeamComponent } from './create-team/create-team.component';
import { MyTeamsComponent } from './my-teams/my-teams.component';
import { TeamStubComponent } from './team-stub/team-stub.component';
import { TeamRestoreComponent } from './team-restore/team-restore.component';
import { ShortLinkComponent } from './short-link/short-link.component';
import { ImageCaptureComponent } from './image-capture/image-capture.component';
import { ImageMultiEditorComponent } from './image-multi-editor/image-multi-editor.component';
import { GameImageManagerComponent } from './game-image-manager/game-image-manager.component';
import { SurveyFeedComponent } from './survey-feed/survey-feed.component';
import { SurveysComponent } from './surveys/surveys.component';
import { AdaMessageComponent } from './ada-message/ada-message.component';
import { DiceButtonComponent } from './dice-button/dice-button.component';
import { CustomTeamSelectionComponent } from './custom-team-selection/custom-team-selection.component';
import { TeamNameComponent } from './team-name/team-name.component';

// export const options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    GameComponent,
    ProfileComponent,
    JoinComponent,
    HomeComponent,
    AuthComponent,
    InviteTeammateModalComponent,
    EnterNameComponent,
    ImageCropperModalComponent,
    LinkJoinComponent,
    AdsCarouselComponent,
    PastAnswersComponent,
    AuthWidgetComponent,
    ContactChooserComponent,
    CodeVerifyInputComponent,
    GameTeamManagerComponent,
    TokenInviteComponent,
    TeamPageComponent,
    CreateTeamComponent,
    MyTeamsComponent,
    TeamStubComponent,
    TeamRestoreComponent,
    ShortLinkComponent,
    ImageCaptureComponent,
    ImageMultiEditorComponent,
    GameImageManagerComponent,
    SurveyFeedComponent,
    SurveysComponent,
    AdaMessageComponent,
    DiceButtonComponent,
    CustomTeamSelectionComponent,
    TeamNameComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    MatIconModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    NgxMaskModule.forRoot(),
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AngularCropperjsModule,
    NgxDropzoneModule,
    ImageCropperModule,
    AvatarModule,
    QRCodeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
