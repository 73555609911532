import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TeamService } from '../services/team.service';
import { finalize } from 'rxjs/operators';
import { SharedService } from '../services/shared.service';

@Component({
	selector: 'app-create-team',
	templateUrl: './create-team.component.html',
	styleUrls: ['./create-team.component.scss']
})
export class CreateTeamComponent implements OnInit {

	isLoggedIn: boolean;

	team_name = "";
	team_slogan = "";
	err = "";
	loading: boolean;

	constructor(private router: Router, private teamServe: TeamService, private sharedService: SharedService) { }

	ngOnInit(): void {
		this.isLoggedIn = localStorage.getItem('auth_token') ? true : false;
	}

	generateTeamName() {
		this.team_name = this.sharedService.generateTeamName()
	}

	toAuth() {
		let params: any = { previous: window.location.pathname }
		this.router.navigate(['/auth'], { queryParams: params });
	}

	createTeam() {
		this.err = this.team_name ? "" : "A team name is required";
		if (this.err) return;
		this.loading = true;
		this.teamServe.createTeam(this.team_name.substring(0,125), this.team_slogan)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: TeamRecord) => this.router.navigate([`/team/${res.kt_team_id}`]),
				err => this.err = err.error.detail)
	}

}
