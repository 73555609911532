<button *ngIf="!user" (click)="startLogin()" class="log-in">Log In</button>
<div *ngIf="user">
	<div *ngIf="user" class="user-thumb" (click)="showMenu = !showMenu">
		<img src="{{user.profile_img}}">
	</div>
	<div *ngIf="showMenu" class="user-menu" (mouseleave)="showMenu = false">
		<h3>{{user.player_name}}</h3>
		<ul>
			<li class="hrz-between" (click)="navigateTo('profile')">
				<mat-icon aria-hidden="false" aria-label="Person icon">person</mat-icon>
				<span>Profile</span>
			</li>
			<li class="hrz-between" (click)="navigateTo('team/list')">
				<mat-icon aria-hidden="false" aria-label="Group icon">groups</mat-icon>
				<span>Teams</span>
			</li>
			<li class="hrz-between" (click)="logout()">
				<mat-icon aria-hidden="false" aria-label="Logout icon">logout</mat-icon>
				<span>Logout</span>
			</li>
		</ul>
	</div>
</div>
<div *ngIf="loggingOut" class="overlay">
	<h1>Logging out...</h1>
</div>