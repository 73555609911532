// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as uuid from "uuid";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    private router: Router,
    ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clientId = localStorage.getItem('kt_client_id');
    if (!clientId) {{
    	clientId = uuid.v4();
    	localStorage.setItem('kt_client_id', clientId)
    }}
    let headers: any = {'user-client-id': clientId}
    let bearer = this.auth.getToken();
    if (bearer) headers['Authorization'] = `Bearer ${bearer}`;
    request = request.clone({
      setHeaders: headers,
      url: request.url,
    });
    // console.log('request:', request);
    return next.handle(request)
    .pipe(
      tap(() =>
      	{},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {

            if(err.status === 401){
              this.auth.setToken(null);
              window.location.href = "/"
            }

          }
    }));
  }
}


