import { Component, ElementRef, OnInit, ViewChild, HostListener, EventEmitter, Input, Output } from '@angular/core';
import { GameService } from '../services/game.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { SharedService } from '../services/shared.service';

@Component({
	selector: 'app-join',
	templateUrl: './join.component.html',
	styleUrls: ['./join.component.scss']
})

export class JoinComponent implements OnInit {
	code: string;
	targetGame: TargetGame;
	chosenTeam: TeamStub;
	memberList: Member[];
	teamList: TeamStub[];
	loading = false;
	teamLoading = true;
	editName: boolean;
	teamLocked: boolean;
	err = "";

	hideOverlay = true;
	hideWarn = false;
	isLoggedIn: boolean;

	numPlayers: number;
	readyJoin: boolean;
	invites: Invite[] = []

	selected_team: any

	constructor(
		private gameService: GameService,
		private router: Router,
		private route: ActivatedRoute,
		private auth: AuthService,
		private sharedService: SharedService
	) { }

	ngOnInit(): void {
		this.isLoggedIn = this.auth.getToken() ? true : false;
		if (!this.isLoggedIn) {
			this.hideOverlay = sessionStorage.getItem('guest_session') ? true : false;
			if (this.hideOverlay) {
				let savedCode = sessionStorage.getItem('target_code');
				if (savedCode) {
					this.code = savedCode;
					sessionStorage.removeItem('target_code')
					this.findGame();
				}
			}
		}
	}

	// refreshInvites(pInput: any) {
	// 	this.numPlayers = (!pInput.value || pInput.value < 1) ? 1 : Math.floor(pInput.value);
	// 	while (this.numPlayers - 1 > this.invites.length) {
	// 		this.invites.push({})
	// 	}
	// 	while (this.numPlayers - 1 < this.invites.length) {
	// 		this.invites.pop()
	// 	}
	// 	this.readyJoin = true;
	// }

	generateTeamName() {
		this.chosenTeam.team_name = this.sharedService.generateTeamName()
    this.chosenTeam.team_name = this.chosenTeam.team_name.substring(0,125);
	}

	normalizePlayerNum() {
		if (!this.numPlayers || this.numPlayers < this.invites.length + 1) {
			this.numPlayers = this.invites.length + 1
		}
		else this.numPlayers = Math.floor(this.numPlayers);
	}

	addInvite() {
		this.invites.push({})
	}

	setReady(val: number) {
		if (!this.isLoggedIn) {
			if (val) this.readyJoin = true;
			else this.readyJoin = false;
		}
	}

	removeInvite(i: number) {
		this.invites.splice(i, 1);
	}

	setInvite(invite: Invite, choice: string) {
		let iKeys = Object.keys(invite)
		for (let k of iKeys) {
			invite[k] = undefined
		}
		if (!choice) return;
		if (choice === 'email') {
			invite.inviteBy = 'email'
			invite.email = ""
		}
		else if (choice === 'phone') {
			invite.inviteBy = 'phone'
			invite.phone = ""
		}
		else {
			for (let m of this.memberList) {
				if (m.playerid === choice) {
					invite.inviteBy = 'playerid'
					invite.playerid = choice
				}
			}
		}
	}

	updateContact(invite: Invite, contact: string) {
		invite[invite.inviteBy] = contact;
	}

	findGame() {
		this.loading = true;
		this.err = "";
		this.gameService.findGameByCode(this.code)
			.subscribe(
				(res: { in_game: boolean, game: TargetGame }) => {
					if (res.in_game) {
						this.router.navigate([`/game/${res.game.gameid}`])
					}
					else {
						this.targetGame = res.game;
						this.loading = false;
						this.getMyTeams();
					}
				},
				err => {
					this.err = err.error.detail;
					this.loading = false;
				})
	}

	toAuth() {
		sessionStorage.setItem('guest_session', "yes");
		let params: any = { previous: window.location.pathname }
		if (this.targetGame) sessionStorage.setItem('target_code', this.code)
		this.router.navigate(['/auth'], { queryParams: params });
	}

	toRestore() {
		this.router.navigate(['/team/restore'])
	}

	guest() {
		sessionStorage.setItem('guest_session', "yes");
		this.hideOverlay = true
	}

	getMyTeams() {
		this.gameService.getTeams(true)
			.subscribe((teams: TeamStub[]) => {
				this.teamList = this.processTeamList(teams);
				this.teamLoading = false;
			},
				err => {
					let teamDef: any = []
					this.teamList = teamDef;
					this.teamLoading = false;
					this.err = "Error retrieving teams!";
				})
	}

	processTeamList(myTeams: TeamStub[]) {
		let gameTeams = this.targetGame.teams;
		for (let t of myTeams) {


			for (let i = gameTeams.length - 1; i >= 0; i--) {
				if (gameTeams[i].my_team) {
					this.router.navigate(['game', this.targetGame.gameid]);
				}
				else if (t.kt_team_id === gameTeams[i].kt_team_id) {
					t.in_game = true;
					t.team_code = gameTeams[i].team_code
					gameTeams.splice(i, 1);
				}
			}
		}
		return myTeams;
	}

	resetForm() {
		this.teamLocked = false;
		this.targetGame = null;
		this.teamList = null;
		this.chosenTeam = null;
		this.teamLoading = true;
		this.editName = false;
	}

	joinGame(): void {
		this.loading = true;
		this.err = "";
		let invites = []
		for (let i of this.invites) {
			if (i.inviteBy) {
				let dupe = false
				for (let inv of invites) {
					if (i.inviteBy === inv.inviteBy) {
						if (i[i.inviteBy] === inv[i.inviteBy]) {
							dupe = true
							break;
						}
					}
				}
				if (!dupe) invites.push(i);
			}
		}
		this.normalizePlayerNum()
		this.gameService.joinGame(
			this.code,
			this.chosenTeam.team_name.substring(0,125),
			this.chosenTeam.kt_team_id,
			this.numPlayers,
			invites
		)
			.subscribe(
				joinResult => {
					window.location.href = `/game/${joinResult.game_id}`;
				},
				err => {
					this.err = err.error.detail;
					this.loading = false;
				});
	}

	hasAllDetails(): boolean {
		if (this.code && this.code.length === 6 && this.chosenTeam && this.chosenTeam.team_name) {
			return true;
		}
		return false;
	}

	setTeam(selected?: any) {

		selected = this.selected_team

		console.log(selected);


		if (selected === "new") this.chosenTeam = { team_name: "", kt_team_id: "new", members: [], team_image: "" };
		else {
			for (let t of this.teamList) {
				if (t.kt_team_id === selected) {
					console.log(t);
					this.chosenTeam = t;
					let me = this.auth.getPlayer()
					this.memberList = t.members.filter((v: Member) => v.playerid !== me.playerid);
				}
			}
		}
		this.editName = false;
		if (this.chosenTeam.in_game) {
			this.router.navigate([`/auth/link/join/${this.targetGame.gameid}/team/${this.chosenTeam.team_code}/`])
		}
	}

	toggleEditName() {
		if (this.editName) {
			this.editName = false;
			//this.setTeam(this.chosenTeam.team_id)
		}
		else this.editName = true;
	}

}

type TargetGame = {
	gameid: string,
	venue: string,
	title: string,
	teams: TeamStub[]
}

type TeamStub = {
	team_name: string
	kt_team_id: string
	my_team?: boolean
	in_game?: boolean
	team_code?: string
	members: Member[]
	team_image: string
}

type Member = {
	invited: string,
	joined: string,
	player_name: string,
	playerid: string
}

type Invite = {
	inviteBy?: string,
	playerid?: string,
	email?: string,
	phone?: string,
	player_name?: string
}
