<h1 *ngIf="loading" class="load-message">{{ loadingMessage }}</h1>

<h1 *ngIf="!loading && err" class="error-message">{{err}}</h1>

<div *ngIf="!loading && !err" class="get-name">
	<h1>Welcome to King Trivia!</h1>
	<label>Please choose your display name:</label>
	<input type="text" [(ngModel)]="name"><br>
	<button [disabled]="!name" (click)="setName()">Join Game</button>
</div>
