<div *ngIf="!isSending && !verifying" class="center">
	<h2 class="center-text">Verification code sent to {{contact}}</h2>
	<p *ngIf="err" class="error-message">{{err}}</p>
	<div class="verifier">
		<input type="number" [(ngModel)]="code">
		<button (click)="verifyCode()">Verify</button>
	</div>
	<h3 class="center-text">
		<span>Didn't receive your code? </span>
		<span [ngClass]="!resendInSec ? 'sim-link' : ''" (click)="sendCode()">
			<span>Resend</span>
			<span *ngIf="resendInSec"> in {{resendInSec}} seconds</span>
		</span>
		<span> or </span>
		<span class="sim-link" (click)="goBack()">Go Back</span>
	</h3>
</div>
<div *ngIf="isSending">
	<h1 class="center-text">Resending...</h1>
</div>
<div *ngIf="verifying">
	<h1 class="center-text">Verifying...</h1>
</div>
