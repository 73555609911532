import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  rootUri = environment.root_uri;

  constructor(
    private http: HttpClient
  ) { }

  getCurrentGames(): Observable<GameStub[]> {
    return this.http.get<GameStub[]>(this.rootUri + '/api/player/game/list');
  }

  // joining a game will work even if called multiple times in case of rejoining
  joinGame(code: string, team_name: string, kt_team_id: string, num_players: number, invites: any[]): Observable<any> {
    return this.http.post<any>(this.rootUri + '/api/player/game/code/join', { code, team_name, kt_team_id, num_players, invites });
  }

  joinGameById(id: string, team_code?: string): Observable<any> {
    let postBody:any = {};
    if (team_code) postBody['team_code'] = team_code;
    return this.http.post<any>(this.rootUri + '/api/player/game/' + id + '/join', postBody);
  }

  getTeams(joined_only: boolean = false): Observable<any> {
    return this.http.get(`${this.rootUri}/api/player/team/list?rnd=${Math.random()}${joined_only ? '&joined=yes' : ''}`);
  }

  // To be called after joining a game to get the game's current status (works for reload)
  loadGame(game_id: string): Observable<any> {
    return this.http.get<any>(this.rootUri + `/api/player/game/load?game_id=${game_id}`);
  }

  answer(game_id: string, round_num: number, question_num: number, answer: string): Observable<SaveResult> {
    return this.http.put<SaveResult>(this.rootUri + '/api/game/answer', { game_id, round_num, question_num, answer });
  }

  submitRound(game_id: string, round_id: number, answers: any, doubled: boolean) {
    let postBody = { doubled, answers }
    return this.http.post(this.rootUri + '/api/player/game/' + game_id + '/round/' + round_id + "/submit", postBody);
  }

  suggestAnswer(game_id: string, round_index: number, q_idx: number, value: string): Observable<any> {
    return this.http.post<any>(this.rootUri + '/api/game/suggestanswer', {game_id, round_index, q_idx, suggestion: value});
  }

  toggleDouble(game_id: string, round_id: number, doubled: boolean) {
    return this.http.post(this.rootUri + "/api/player/game/" + game_id + "/round/" + round_id + "/double", {doubled})
  }

  cancelInvite(game_id: string, team_code: string, player: Player): Observable<any> {
    return this.http.post<any>(this.rootUri + '/api/player/game/' + game_id + '/team/' + team_code + '/cancel-invite', {email: player.email, phone: player.phone});
  }

  dropPlayer(game_id: string, kt_team_id: string, player: Player): Observable<any> {
    return this.http.post<any>(this.rootUri + '/api/player/game/' + game_id + '/team/' + kt_team_id + '/drop-player', {...player});
  }

  inviteTeammate(game_id: string, team_code: string, contact: {'email': string, 'phone': string}): Observable<any> {
    return this.http.post<any>(this.rootUri + '/api/player/game/' + game_id + '/team/' + team_code + '/invite', contact);
  }

  promoteCaptain(game_id: string, player_code: string, team_code: string): Observable<any> {
    return this.http.post<any>(this.rootUri + '/api/player/game/' + game_id + '/team/' + team_code + '/promote-captain', {player_code});
  }

  findGameByCode(code: string) {
  	return this.http.get(this.rootUri + '/api/player/game/' + code + '/exists');
  }

  getInivteToken(game_id: string, team_code: string) {
  	return this.http.post(`${this.rootUri}/api/player/game/${game_id}/team/${team_code}/invite-token/generate`, {})
  }

  sendSelfInvite(game_id: string, team_code: string, inviter: string, email: string, phone: string) {
  	return this.http.post(`${this.rootUri}/api/player/game/${game_id}/invite-token/invite`, {team_code, inviter, email, phone});
  }

  upgradeTeam(game_id: string, team_code: string, teamid: string) {
  	return this.http.post(`${this.rootUri}/api/player/game/${game_id}/team/${team_code}/register`, {teamid});
  }

  declineInvite(game_id: string, team_code: string) {
  	return this.http.post(`${this.rootUri}/api/player/game/${game_id}/team/${team_code}/decline-invite`, {});
  }
}

export interface SaveResult {
  status: 'success' | 'error';
  message: string;
}
