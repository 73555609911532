<div class="content-container">
	<div class="game-container">


		<div class="content">
			<div *ngIf="err">
				<p class="error-message">
					<span>{{err}}</span>
					<span *ngIf="deadWS">
						<br>
						<button (click)="resetConnection()" class="reset-btn">
              {{ refreshButtonLink== null ? "Refresh": "Go Back" }}
            </button>
					</span>
				</p>
			</div>
			<div *ngIf="game && !err">
				<div class="game-ctrl-header">
					<span class="action-button-container">

						<div class="action-button" (click)="tipQM = true">
							<div class="action-icon">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="2em" height="2em"
									viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round"
										d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
								</svg>


								<div *ngIf="isLoggedIn && game?.my_team && !game.my_team.kt_team_id"></div>
							</div>
							<div class="action-label">Tip your QM</div>
						</div>

						<div class="action-button" (click)="toggleMenu('imgMenuPosition')">
							<div class="action-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 20 20"
									fill="currentColor" class="w-5 h-5">
									<path fill-rule="evenodd"
										d="M1 8a2 2 0 0 1 2-2h.93a2 2 0 0 0 1.664-.89l.812-1.22A2 2 0 0 1 8.07 3h3.86a2 2 0 0 1 1.664.89l.812 1.22A2 2 0 0 0 16.07 6H17a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8Zm13.5 3a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM10 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
										clip-rule="evenodd" />
								</svg>
							</div>
							<div class="action-label">Action Shots</div>
						</div>

						<div class="action-button" (click)="toggleMenu()">
							<div class="action-icon">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="2em" height="2em"
									viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round"
										d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
								</svg>

								<div *ngIf="isLoggedIn && game?.my_team && !game.my_team.kt_team_id"></div>
							</div>
							<div class="action-label">My Team</div>
						</div>


					</span>
				</div>

				<div *ngIf="!game.round?.tiebreaker" class="game-title">
					<span *ngIf="game.round" class="venue-name">Round {{game.round?.round_order + 1}}</span>
					<span *ngIf="!game.round" class="venue-name">{{game.niceTitle()}} at {{game.venue}}</span>
				</div>

				<div *ngIf="game.status === 'ended'">
					<h2 class="round-message">The game has ended. Thanks for playing!</h2>
				</div>

				<div *ngIf="game.game_started && !game.round && game.status !== 'ended'">
					<h2 class="round-message">Your Quizmaster is scoring the answers and will start the next round soon!
					</h2>
				</div>

				<div *ngIf="!game.game_started && !game.round && game.status !== 'ended'">
					<h2 class="round-message">Welcome! Your Quizmaster will start the game soon!</h2>
				</div>

				<div *ngIf="game.round && game.status !== 'ended' && game?.round?.submitted">
					<h2 class="round-message">Your round has been submitted!</h2>
				</div>

				<div class="lg-my">
					<app-past-answers *ngIf="game?.submitted?.length && (!game.round || game.round.submitted)"
						[submitted]="game.submitted" [game]="game"></app-past-answers>
				</div>

				<div *ngIf="game?.game_started && game?.status !== 'ended' && !didSSPreview">
					<app-survey-feed *ngIf="game && (!game.round || game?.round?.submitted)"
						[previewMode]="true"></app-survey-feed>
				</div>

				<div class="round" *ngIf="game.round && game.status !== 'ended' && !game?.round?.submitted">

					<div class="round-title">{{game.round?.title}}</div>
					<div class="round-caption">
						<span>{{game.round?.subtitle}}</span>
						<em *ngIf="game.round?.player_instructions"><br>{{game.round?.player_instructions}}</em>
					</div>
					<div *ngIf="game.round?.questions.length; else noQuestions">

						<div *ngIf="game.round?.matches?.length" class="hrz-center">
							<div class="w-100">
								<div class="center-text"><strong>Possible Answers</strong></div>
								<div class="match-box">
									<div *ngFor="let m of game.round.matches">{{m}}</div>
								</div>
							</div>
						</div>

						<div class="question" *ngFor="let question of game.round?.questions; index as q_idx"
							[ngClass]="question['source'] === 'push' ? 'animated bounceIn': ''">
							<div *ngIf="question.image" class="question-image">
								<img src="{{question.image}}">
							</div>
							<label for="teamname"><span *ngIf="!game.round.single_answer">{{q_idx + 1}}.
								</span>{{question?.question_text}}</label>
							<div *ngIf="!game.round.single_answer || q_idx === game.round.questions.length - 1">
								<ng-container
									*ngFor="let answer of question?.answers; index as a_idx;trackBy:trackByIndex">
									<input *ngIf="game?.me?.is_captain && !game.round.matches.length" type="text"
										[(ngModel)]="question.answers[a_idx]" (focusout)="backupAnswers()">
									<select *ngIf="game?.me?.is_captain && game.round.matches.length"
										(change)="changedAnswer(question, q_idx, a_idx, $event.target.value)">
										<option value=""></option>
										<option *ngFor="let m of game.round.matches" [value]="m"
											[selected]="question.answers[a_idx] === m">{{m}}</option>
									</select>
									<!-- <span *ngIf="question.answers[a_idx]">Your Team's Answer: {{question.answers[a_idx]}}</span> -->
									<!-- <span *ngIf="question.answers[a_idx]">Captain's Answer: {{question.answers[a_idx]}}</span> -->
									<!-- <div class="suggestions" *ngIf="question?.answers?.length === 1">
	                <span
	                  class="suggestion"
	                  *ngFor="let suggestion of question.suggestions"
	                  (click)="suggest(q_idx, a_idx, suggestion.text)">
	                    <span class="number">{{suggestion.count}}</span>
	                    {{suggestion.text}}
	                  </span> -->
								</ng-container>
							</div>
						</div>

					</div>

					<ng-template #noQuestions>
						<div class="hrz-center">
							<h1 class="round-message">No questions released yet.</h1>
						</div>
					</ng-template>

					<div class="mobile-spacer"></div>
				</div>
			</div>
		</div>

		<app-ads-carousel *ngIf="game && (!game?.round || game.round.submitted)" [game]="game"></app-ads-carousel>

	</div>
</div>

<div class="footer" *ngIf="game && game?.me?.is_captain && game?.round && !game?.round?.submitted && game?.submissions_open">
	<span *ngIf="game && game.round?.double_or_nothing && !game.round.submitted" class="double-nothing"
		(click)="toggleDouble()">
		<div class="dbl-not-check">
			<input type="checkbox" name="double" [checked]="game.round.doubled" [disabled]="!game.me.is_captain">
		</div>
		<div class="dbl-not-label">
			<span class="w-max-content">
				<span>Double or<br>Nothing</span>
				<span *ngIf="!game.round?.doubled">?</span>
				<span *ngIf="game.round?.doubled">!</span>
			</span>
		</div>
	</span>

	<button (click)="confirmSub = true">SUBMIT</button>
</div>

<div [@slide]="menuPosition" class="team-manager" *ngIf="menuPosition === 'show'">
	<app-game-team-manager [game]="game" [isLoggedIn]="isLoggedIn" [invErr]="invErr" [regPrompt]="registrationPrompt()"
		(sanity)="loadGame()" (closeToggle)="toggleMenu()" (auth)="toAuth()"
		(err)="invErr = $event"></app-game-team-manager>
</div>

<div [@slide]="imgMenuPosition" class="img-manager" *ngIf="imgMenuPosition === 'show'">
	<app-game-image-manager [game]="game" (close)="toggleMenu('imgMenuPosition')"></app-game-image-manager>
</div>

<div *ngIf="confirmSub" class="modal-background">
	<div class="modal-container">
		<div class="modal-header">
			<h2>Submit {{game.round.single_answer ? 'your Answer' : 'these Answers'}}?</h2>
			<i (click)="confirmSub = false" class="material-icons">clear</i>
		</div>
		<div class="modal-content">
			<p *ngIf="game?.round?.doubled" class="double-or-nothing-sub">
				<span>Double or nothing is activated</span>
			</p>
			<div *ngFor="let q of game.round.questions; let i = index">
				<div *ngIf="!game?.round?.single_answer || i === game?.round?.questions?.length - 1"
					class="question-confirm">
					<div class="hrz-start">
						<div *ngIf="!game?.round?.single_answer">Q{{i + 1}}.</div>
						<div *ngIf="game?.round?.single_answer">Your Answer: </div>
						<div class="answer-confirm">
							<div *ngFor="let a of q.answers">{{a}}</div>
						</div>
					</div>
					<p *ngIf="game?.round?.single_answer && game?.round?.questions?.length === i + 1"
						class="empty-answer-warning">
						<span *ngIf="q.emptyAnswers()">
							<i class="material-icons">warning</i>
							<span>Submitting an empty answer will forfeit this round. If you don't know the answer, just
								wait for your Quizmaster to release more clues!</span>
						</span>
					</p>
				</div>
			</div>
			<p *ngIf="game?.round?.emptyAnswers() && !game?.round?.single_answer" class="empty-answer-warning">
				<i class="material-icons">warning</i>
				<span>You have some blank answers!</span>
			</p>
		</div>
		<div class="modal-footer">
			<button class="cancel-btn" (click)="confirmSub = false">Go Back</button>
			<button (click)="submitRound()">Submit</button>
		</div>
	</div>
</div>


<div *ngIf="tipQM" class="modal-background">
	<div class="modal-container">
		<div class="modal-header">
			<h2>Tip Your Quizmaster</h2>
			<i (click)="tipQM = false" class="material-icons">clear</i>
		</div>
		<div class="modal-content">
			<p>Want to leave a tip for your amazing QM? No Problem!</p>
			<p>
				<span>Feel free to tip in cash</span>
				<span *ngIf="game.host_venmo.length">, or click the link below to tip via venmo.</span>
				<span *ngIf="!game.host_venmo.length">, or ask your host if they will accept tips via Venmo.</span>
			</p>
			<div *ngIf="game.host_venmo.length">
				<p><em>NOTE: The link(s) below may not work properly if you do not have the venmo app installed.</em>
				</p>
				<ul>
					<li *ngFor="let qm of game.host_venmo" class="ven-link">
						<a href="https://venmo.com/{{qm.venmo}}?txn=pay" target="_blank">{{qm.name}} (@{{qm.venmo}})</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="modal-footer">
			<button class="cancel-btn" (click)="tipQM = false">Go Back</button>
		</div>
	</div>
</div>

<div *ngIf="captainMsg" class="modal-background">
	<div class="modal-container">
		<div class="modal-header">
			<h2>{{captainMsg === 'You' ? 'You are' : captainMsg + ' is'}} the captain now!</h2>
			<i (click)="captainMsg = ''" class="material-icons">clear</i>
		</div>
		<div class="modal-footer">
			<button (click)="captainMsg = ''">Ok</button>
		</div>
	</div>
</div>
