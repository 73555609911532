<div class="menu" *ngIf="menuPosition !== 'top'" [class.hidden]="menuPosition === 'top'" [@slide]="menuPosition">
  <div class="menu-section" (click)="toggleMenu()">
    <div class="header-top">
    	<span>V{{ver}}</span>
    	<mat-icon class="close" aria-hidden="false" aria-label="Close menu">close</mat-icon>
    </div>

    <!-- Create a Team -->
    <div class="menu-item menu-item1" (click)="navigateTo($event, '/team/create')">
      <mat-icon aria-hidden="false" aria-label="Team Icon">groups</mat-icon>
      <div class="text">Create a Team</div>
    </div>

    <!-- Join -->
    <div class="menu-item menu-item1" (click)="navigateTo($event, '')">
      <mat-icon aria-hidden="false" aria-label="Confirmation ticket icon">confirmation_number</mat-icon>
      <div class="text">Join Game</div>
    </div>

      <!-- League Rankings -->
    <div class="menu-item menu-item1" (click)="navigateToExternal('kingtrivia.com/rankings')">
      <mat-icon aria-hidden="false" aria-label="Trophy Icon">emoji_events</mat-icon>
      <div class="text">League Rankings</div>
    </div>

      <!-- Take the Survey -->
    <div class="menu-item menu-item1" (click)="navigateTo($event, '/survey')">
      <mat-icon aria-hidden="false" aria-label="Survey Icon">fact_check</mat-icon>
      <div class="text">Take the Survey</div>
    </div>

    <!-- Private Events -->
    <div class="menu-item menu-item1" (click)="navigateToExternal('kingtrivia.com/featured/private-events/')">
      <mat-icon aria-hidden="false" aria-label="celebration Icon">celebration</mat-icon>
      <div class="text">Book a Private Event</div>
    </div>

    <!-- Become a QM -->
    <div class="menu-item menu-item1" (click)="navigateToExternal('kingtrivia.com/about-king-trivia/become-a-quizmaster/')">
      <mat-icon aria-hidden="false" aria-label="Microphone Icon">mic</mat-icon>
      <div class="text">Become a Quizmaster</div>
    </div>
  </div>

  <div class="menu-section">
    <p class="menu-bottom-row">King Trivia <a class="a-buffer" href="https://kingtrivia.com/king-trivia-privacy-policy/" target="_blank">Privacy Policy</a> and <a class="a-buffer" href="https://kingtrivia.com/king-trivia-website-terms-of-use/" target="_blank">Terms of Use</a></p>

    <app-ada-message></app-ada-message>
  </div>

</div>

<div *ngIf="showMenu" class="header">
	<mat-icon aria-hidden="false" aria-label="Main menu"  (click)=toggleMenu()>menu</mat-icon>
	<div class="menu-logo">
		<img src="/assets/img/KTL_LOGO.png"  width="auto" height="50px">
	</div>
	<div class="auth-widget">
		<app-auth-widget (userFound)="set2FABanner($event)"></app-auth-widget>
	</div>
</div>
<div *ngIf="showMenu && warn2FA" class="head-banner">
	<div>
		<span>Don't lose access to your teams! <span class="sim-link" (click)="navigateTo($event, 'profile')">Add your {{missingContact}}</span> to keep your account secure.</span>
		<span class="closer" (click)="dismiss2FA()">X</span>
	</div>
</div>
