import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { finalize } from 'rxjs/operators';
import * as jwtJsDecode from 'jwt-js-decode';

@Component({
  selector: 'app-auth-widget',
  templateUrl: './auth-widget.component.html',
  styleUrls: ['./auth-widget.component.scss']
})
export class AuthWidgetComponent implements OnInit {

  token: string;
  user: User;

  showMenu: boolean;
  loggingOut: boolean;

  warn2FA: boolean;

  @Output() userFound: EventEmitter<User> = new EventEmitter()

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  	this.token = this.auth.getToken()
  	if (this.token) {
  		this.user = this.getDataFromToken();
  		this.userFound.emit(this.user)
  	}
  	console.log(this.user)
  }

  getDataFromToken() {
  	return this.parseJwt(this.token)
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  navigateTo(route: string) {
  	this.showMenu = false;
  	this.router.navigate([`/${route}`]);
  }

  startLogin() {
  	this.router.navigate(['/auth'], {queryParams: {previous: window.location.pathname}})
  }

  logout() {
  	this.loggingOut = true;
  	this.auth.logout()
  		.pipe(finalize(() => {
  			this.auth.setToken(null);
  			window.location.href = "/";
  		}))
  		.subscribe()
  }

}
