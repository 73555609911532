import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authServe: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let isAuth = !!localStorage.getItem('auth_token');

    if (!isAuth) {
      this.router.navigate(['/']);
    }
    // else {
    //   this.authServe.nameCheck()
    //     .subscribe((res: any) => {
    //       if (res.status !== "success")
    //         this.router.navigate(['/auth']);
    //         return isAuth;
    //     })
    // }

    return isAuth;
    
  }

}
