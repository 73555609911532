import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

	rootUri = environment.root_uri;

	constructor(private http: HttpClient) { }

	getSurveyQuestion(): Observable<SurveyResult> {
		return this.http.get<SurveyResult>(`${this.rootUri}/api/survey/player/next`)
	}

	surveyResponse(sid: string, response: string): Observable<SurveyResult> {
		return this.http.post<SurveyResult>(`${this.rootUri}/api/survey/${sid}/player/response`, {response})
	}

	surveySkip(sid: string, reason: string): Observable<SurveyResult> {
		return this.http.post<SurveyResult>(`${this.rootUri}/api/survey/${sid}/player/skip`, {reason})
	}

}
