<div class="content-container">
  <div class="content">
    <div class="title">
      My Profile
    </div>

    <p *ngIf="msg" class="system-message">{{msg}}</p>

    <p *ngIf="err" class="error-message">{{err}}</p>

    <div class="content-block" *ngIf="edit === 'name' && !verifying">
      <div class="name-edit">
        <div>
          <label>Choose Your Display Name</label>
          <input type="text" [(ngModel)]="name">
        </div>
      </div>
      <div class="avatar-link link">
        <span class="cancel-btn" (click)="cancelChange()">Cancel</span>
        <span class="edit-btn" (click)="updateName()">Submit</span>
      </div>
    </div>

    <div class="content-block" *ngIf="edit === 'phone' && !verifying">
      <app-contact-chooser
      	[contactType]="'phone'"
      	[loading]="loading"
      	[lockContactType]="true"
      	[invalidInput]="invalidInput"
      	(submit)="startUpdate('phone')"
      	(updateContact)="setPhone($event)"></app-contact-chooser>
      <div class="avatar-link link">
        <span class="cancel-btn" (click)="cancelChange()">Cancel</span>
      </div>
    </div>

    <div class="content-block" *ngIf="edit === 'email' && !verifying">
      <app-contact-chooser
      	[contactType]="'email'"
      	[loading]="loading"
      	[lockContactType]="true"
      	[invalidInput]="invalidInput"
      	(submit)="startUpdate('email')"
      	(updateContact)="setEmail($event)"></app-contact-chooser>
      <div class="avatar-link link">
        <span class="cancel-btn" (click)="cancelChange()">Cancel</span>
      </div>
    </div>

    <div *ngIf="verifying">
    	<app-code-verify-input
    		[isSending]="isSending"
    		[verifying]="confirming"
    		[contact]="contactVerifying"
    		[resendInSec]="resendInSec"
    		(cancel)="cancelChange()"
    		(submit)="confirmUpdate($event)"
    		(resend)="startUpdate(edit)"></app-code-verify-input>
    </div>

    <div class="content-block" *ngIf="!edit && !verifying">
      <div class="avatar">
        <!-- <ngx-avatar size="150" [src]="imageSrc" [name]="name"></ngx-avatar> -->
        <div class="avatar-name">
          {{name}}
          <div *ngIf="email" class="avatar-name-subtitle">
            <mat-icon aria-hidden="false" aria-label="Email icon">email</mat-icon>
            <span>{{email}}</span>
          </div>
          <div *ngIf="phone" class="avatar-name-subtitle">
            <mat-icon aria-hidden="false" aria-label="Phone icon">phone</mat-icon>
            <span>{{phone}}</span>
          </div>
        </div>
      </div>
      <div class="avatar-link link">
        <span class="edit-btn" (click)="edit = 'name'">Edit Name</span>
        <span *ngIf="!phone || (phone && email)" class="edit-btn" (click)="edit = 'phone'">{{!phone ? 'Add' : 'Edit'}} Phone Number</span>
        <span *ngIf="!email || (phone && email)" class="edit-btn" (click)="edit = 'email'">{{!email ? 'Add' : 'Edit'}} Email</span>
        <!-- <input hidden type="file" name="avatar-file-upload" id="avatar-file-upload" (change)="handleFiles($event)" accept=".jpg, .jpeg, .png, .bmp, .gif"> -->
      </div>
    </div>
  </div>
  <h1 *ngIf="loading" class="center-text">Loading...</h1>
</div>
