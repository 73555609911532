<div #dropdown class="custom-dropdown" id="dropdown">
    <div class="dropdown-select" (click)="toggleDropdown()">{{ placeholder == 'new' ? 'New Team' : placeholder }}</div>
    <div class="dropdown-content">
        <ul>
            <li (click)="onItemClick({'team_name':'new','kt_team_id':'new'})">
                <div class="custom-item-with-leading">
                    <div class="rounded-avatar flex items-center justify-center" id="new-team-placeholder">
                        <h4>+</h4>
                    </div>
                    <h5 class="custom-item-title">New Team</h5>
                </div>
            </li>
            <li *ngFor="let item of items" (click)="onItemClick(item)">
                <div class="custom-item-with-leading">
                    <img [src]="item.team_image" alt="team_image" width="50px" class="rounded-avatar">
                    <h5 class="custom-item-title">{{ item[optionKeyForLabel] }}</h5>
                </div>
            </li>
        </ul>
    </div>
</div>