<div class="content-container">
	<div class="content">
		<div class="title">Join a Game</div>
		<div *ngIf="!targetGame" class="center">
			<label for="code">Game Code (Ask your Quizmaster)</label>
			<input type="text" placeholder="XXXXXX" mask="000000" id="code" [(ngModel)]="code">
		</div>

		<div *ngIf="targetGame">
			<div class="hrz-center">
				<div class="game-details">
					<div>
						<strong>{{targetGame.title === 'trivia' ? 'Pub Quiz' : (targetGame.title === 'slam') ? 'Survey
							Slam' : targetGame.title}} at {{targetGame.venue}}</strong>


						<div class="hrz-start">
							<img *ngIf="chosenTeam && teamLocked" class="rounded-avatar"
								[src]="selected_team == 'new' ? 'https://kingtrivia.com/wp-content/plugins/king-trivia/images/kt-team-icon.jpg': chosenTeam.team_image"
								alt="team image" width="100">
							<div class="vert-center sm-ml">
								<span>Join Code: {{code}}</span>
								<span *ngIf="teamLocked">Team: {{chosenTeam.team_name}}</span>
							</div>

						</div>



					</div>
					<div>
						<i class="material-icons" (click)="resetForm()">clear</i>
					</div>
				</div>
			</div>

			<div *ngIf="!teamLocked" class="vert-align-center">
				<div *ngIf="!teamLoading" class="team-select">
					<div>
						<label for="selectTeam">Choose Your Team</label>
					</div>
					<div class="vert-center">
						<div class="flex justify-center std-mb" *ngIf="chosenTeam &&selected_team !== 'new' ">
							<div class="flex flex-col items-center">
								<img [src]="chosenTeam.team_image" width="100px" class="rounded-avatar" />
								<strong>{{ selected_team == 'new' ? '':'#'+selected_team }}</strong>
							</div>
						</div>
						<app-custom-team-selection [(ngModel)]="selected_team" [items]="teamList"
							optionKeyForValue="kt_team_id" optionKeyForLabel="team_name"
							(ngModelChange)="setTeam()"></app-custom-team-selection>
						<h3 *ngIf="isLoggedIn" class="center-text std-mt">Have a team #, but don't see it in your list?
							<span class="sim-link" (click)="toRestore()">Search the Archives!</span>
						</h3>
					</div>
				</div>

				<div *ngIf="teamLoading" class="team-load">
					<p>Retrieving Teams...</p>
				</div>

				<div *ngIf="chosenTeam" class="team-load">
					<div *ngIf="chosenTeam.kt_team_id !== 'new' && !editName">
						<span>Edit team name?</span>
						<button (click)="toggleEditName()" class="edit-team-button">Yes</button>
						<button (click)="teamLocked = true" class="edit-team-button no">No</button>
					</div>
					<div *ngIf="editName || chosenTeam.kt_team_id === 'new'" class="hrz-between items-center">

						<app-dice-button (buttonClick)="generateTeamName()"
							*ngIf="editName || chosenTeam.kt_team_id === 'new'"></app-dice-button>

						<div class="flex flex-col">
              <app-team-name class="flex-1" [(model)]="chosenTeam.team_name"></app-team-name>
            </div>

						<div class="vert-end">
							<button (click)="teamLocked = true" class="edit-team-button"
								[disabled]="!chosenTeam.team_name">Done</button>
						</div>

					</div>

				</div>

				<div *ngIf="!isLoggedIn && !hideWarn" class="team-auth-remind w-max-300-p">
					<p>Have a registered team? <span class="sim-link" (click)="toAuth()">Log in to use it!</span></p>
					<div class="vert-center sm-ml" (click)="hideWarn = true">
						<span>X</span>
					</div>
				</div>

			</div>

			<div *ngIf="teamLocked" class="vert-align-center">
				<div *ngIf="!loading" class="hrz-between w-max-300-p">
					<h2>How many players on your team today?</h2>
					<input class="num-players" type="number" [value]="numPlayers"
						(input)="numPlayers = $event.target.value">
				</div>
				<div *ngIf="isLoggedIn && numPlayers && !loading">
					<div class="player-card">
						<h3 class="player-header">Player 1: You</h3>
					</div>
					<div *ngFor="let inv of invites; let i = index" class="player-card">
						<div class="hrz-start">
							<h3 class="player-header">Player {{i + 2}}:</h3>
							<select class="player-select" (change)="setInvite(inv, $event.target.value)">
								<option [value]="''">+ Invite Later</option>
								<option *ngFor="let m of memberList" [value]="m.playerid"
									[selected]="inv.playerid === m.playerid">{{m.player_name}}</option>
								<option [value]="'email'" [selected]="inv.inviteBy === 'email'">+ Invite by Email
								</option>
								<option [value]="'phone'" [selected]="inv.inviteBy === 'phone'">+ Invite by Text
								</option>
							</select>
							<span class="closer" (click)="removeInvite(i)">X</span>
						</div>
						<app-contact-chooser *ngIf="inv.inviteBy === 'email' || inv.inviteBy === 'phone'"
							[contactType]="inv.inviteBy" [lockContactType]="inv.inviteBy" [noSubmit]="true"
							(updateContact)="updateContact(inv, $event)"></app-contact-chooser>
					</div>
					<div class="add-invite" (click)="addInvite()">
						<mat-icon aria-hidden="false" aria-label="Plus Icon">add_circle_outline</mat-icon>
						<span>Invite a Teammate</span>
					</div>
				</div>
				<div *ngIf="numPlayers" class="team-load">
					<button class="join" (click)="joinGame()" [disabled]="loading">
						<span *ngIf="!loading">Join Game</span>
						<span *ngIf="loading">Joining...</span>
					</button>
				</div>
			</div>
		</div>

		<p *ngIf="err" class="error-message">{{err}}</p>

		<button *ngIf="!targetGame" class="join" [disabled]="!code" (click)="findGame()">{{ loading ? 'Finding
			Game...':'Next' }}</button>

		<!-- <img class="join-image" src="/assets/img/riding.png"> -->

	</div>

	<app-ada-message></app-ada-message>

</div>

<div *ngIf="!hideOverlay" class="overlay">
	<h1>Hello Stranger!</h1>
	<h3>Don't forget to log in so that you can save your team, invite your friends, and keep track of your scores!</h3>
	<div>
		<button (click)=toAuth()>Login / Register</button>
	</div>
	<p class="sim-link" (click)="guest()">Continue as Guest</p>
	<p class="bottom-link">King Trivia <a class="a-buffer" href="https://kingtrivia.com/king-trivia-privacy-policy/"
			target="_blank">Privacy Policy</a> and <a class="a-buffer"
			href="https://kingtrivia.com/king-trivia-website-terms-of-use/" target="_blank">Terms of Use</a></p>
</div>
