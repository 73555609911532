import { Component, OnInit, Input } from '@angular/core';
import { Game } from '../classes/Game';

@Component({
  selector: 'app-past-answers',
  templateUrl: './past-answers.component.html',
  styleUrls: ['./past-answers.component.scss']
})
export class PastAnswersComponent implements OnInit {

	@Input() submitted: Submission[] = [];
	@Input() game: Game;

	showAnswers: boolean;
	subIndex: number;

	constructor() { }

	ngOnInit(): void {

	}

	viewSubmissions() {
		if (this.game.round) this.subIndex = this.game.round.round_order;
		else this.subIndex = this.submitted.length - 1;
		this.showAnswers = true;
	}

	nextRound() {
		if (!this.submitted.length) return;
		let count = 0;
		let newIndex = this.subIndex;
		while (count < this.submitted.length) {
			newIndex++;
			if (newIndex >= this.submitted.length) newIndex = 0;
			if (this.submitted[newIndex]) {
				this.subIndex = newIndex;
				return;
			}
			count++;
		}
	}

	previousRound() {
		if (!this.submitted.length) return;
		let count = 0;
		let newIndex = this.subIndex;
		while (count < this.submitted.length) {
			newIndex--;
			if (newIndex < 0) newIndex = this.submitted.length - 1;
			if (this.submitted[newIndex]) {
				this.subIndex = newIndex;
				return;
			}
			count++;
		}
	}

}

type Submission = {
	title: string,
	round_id: number,
	single_answer: boolean,
	doubled: boolean | undefined
	answers: [string[]] | undefined
}
