<h1 mat-dialog-title>Enter your name</h1>
<div mat-dialog-content>
  <label for="name">Name</label>
  <input type="text" id="name" name="name" [(ngModel)]="name">
</div>
<div mat-dialog-actions>
  <!-- <button class="modal-button" (click)="onNoClick()">Cancel</button> -->
  <button class="modal-button" [disabled]="isInvalidName()"[mat-dialog-close]="name" cdkFocusInitial>Done</button>
</div>


