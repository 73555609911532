import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  
  @ViewChild("phoneIn") pInput: ElementRef

  contactType: 'email' | 'phone';
  email = "";
  phone = "";
  name = "";
  invalidInput: boolean;
  isSending = false;
  verifying = false;
  err = '';
  needName: boolean;
  readySend: boolean;
  loading: boolean;
  logInCode: string;
  playerid: string;
  prevPage: string = "/";
  code: string;

  resendInSec = 0;
  timer;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
  	this.route.queryParams
  		.subscribe(params => {
  			if (params.previous) this.prevPage = params.previous
  		})
  }

  sendCode() {
  	this.isSending = true;
  	this.err = ""
  	this.resendInSec = 0;
  	if (this.timer) clearInterval(this.timer);
  	this.authService.registerPlayer(this.name, this.email, this.phone)
  		.pipe(finalize(() => this.isSending = false))
  		.subscribe(
  			(res: any) => {
		      this.playerid = res.playerid
		      this.resendInSec = 60;
		      this.timer = setInterval(this.decrementSeconds.bind(this), 1000);
		    },
		    err => this.err = err.error.detail)
  }

  updateContact(e: string) {
  	this.readySend = false;
  	this.invalidInput = false;
  	this.needName = false;
  	this[this.contactType] = e;
  }

  handleTypeSwitch(type: 'email' | 'phone') {
  	this.needName = false;
  	this.readySend = false;
  	this.invalidInput = false;
  	this.email = ""
  	this.name = ""
  	this.phone = ""
  	this.contactType = type;
  }

  handleSubmit() {
  	if (!this.needName) this.checkExistingContact();
  	else if (this.name) this.sendCode();
  }

  // sendLink(): void {
  //   // alert('Link sent! Check your email.');
  //   this.isSending = true;
  //   this.err = '';
  //   this.authService.MagicLink(this.name, this.email)
  //   .pipe(
  //     finalize( () => this.isSending = false)
  //   )
  //   .subscribe(result => {
  //     this.showConfirm = true;
  //     this.resendInSec = 30;
  //     this.timer = setInterval(this.decrementSeconds.bind(this), 1000)
  //   },
  //   err => this.err = err.error.detail);
  // }

  verifyCode(code: string) {
  	if (!code) {
  		this.err = "You must enter the code to continue"
  		return
  	}
  	this.err = ""
  	this.verifying = true;
  	this.authService.verifyCode(code, this.playerid)
  		.subscribe(
  			(res: any) => {
  				let host = window.location.origin;
        		let url = `${host}${this.prevPage}#token=${res.message}`;
        		window.location.href = url;
  			},
  			err => {
  				this.err = err.error.detail
  				this.verifying = false;
  			})
  }

  decrementSeconds() {
  	this.resendInSec--;
  	if (this.resendInSec < 1) clearInterval(this.timer);
  }

  validContact() {
  	return ((this.contactType === 'email' && this.validEmail()) || (this.contactType === 'phone' && this.validPhone()))
  }

  validEmail(): boolean {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (this.email && this.email.length > 0 && re.test(String(this.email).toLowerCase()));
  }

  validPhone() {
  	return (this.phone && this.phone.length === 10);
  }

  checkExistingContact() {
  	this.loading = true;
  	this.needName = false;
  	this.readySend = false;
  	this.err = "";
  	this.invalidInput = !(this.validContact())
  	if (this.invalidInput) {
  		this.loading = false;
  		return;
  	}
  	this.authService.contactCheck(this.contactType, this[this.contactType])
  		.pipe(finalize(() => this.loading = false))
  		.subscribe(
  			(res) => {
  				this.needName = !res
  				if (!this.needName) this.sendCode();
  			},
  			err => this.err = err.error.detail)
  }

  navigateBack() {
  	this.router.navigate([this.prevPage]);
  }

}
