<div *ngIf="!loading; else loadMsg" class="img-multi-container">
	<div class="img-multi-images">
		<div *ngFor="let img of images; let i = index" class="img-multi-image-container {{img.orientation}}">
			<div class="img-multi-spacer"></div>
			<img src="{{img.url}}">
			<div class="img-multi-overlay" (click)="setFocus(i)">
				<!-- <mat-icon *ngIf="focusImg === i" aria-hidden="false" class="transparent" aria-label="close icon" (click)="removeFocus($event)">close</mat-icon> -->
				<!-- <div *ngIf="focusImg === i">
					<mat-icon *ngIf="queueDelete !== true" aria-hidden="false" aria-label="Delete icon" (click)="startDelete($event)">delete</mat-icon>
					<p *ngIf="queueDelete === true" (click)="deleteImage(i, $event)">Tap Again to Delete</p>
				</div> -->
			</div>
		</div>
		<div class="img-multi-image-container">
			<app-image-capture (upload)="uploadImages($event)"></app-image-capture>
		</div>
	</div>
	<div>
		<p *ngIf="err" class="error-message">{{err}}</p>
	</div>
</div>

<div *ngIf="focusImg !== undefined && !loading" class="image-multi-editor-full-image {{images[focusImg].orientation}}">
	<img src="{{images[focusImg].url}}">
	<div class="image-multi-editor-full-image-ctrls">
		<div (click)="removeFocus($event)">
			<span>Close</span>
			<mat-icon aria-hidden="false" aria-label="Close Fullscreen">close_fullscreen</mat-icon>
		</div>
		<div (click)="deleteImage(focusImg, $event)">
			<span>Delete</span>
			<mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon>
		</div>
	</div>
</div>

<ng-template #loadMsg>
	<h2 class="center-text">{{loading}}</h2>
</ng-template>

