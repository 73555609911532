<div class="content-container">
	<div *ngIf="!loading && team && !editing" class="content relative-pos">
		<h3 *ngIf="err" class="error-message">{{err}}</h3>
		<div class="team-header">
			<h1>Team #{{team.kt_team_id}}:<br><span class="team-name">{{team.team_name}}</span></h1>
			<h2 *ngIf="team.team_slogan" class="team-slogan">"{{team.team_slogan}}"</h2>
		</div>
		<div class="team-info">
			<div class="team-image-container">
				<div class="team-image">
					<img src="{{team.team_image}}">
				</div>
			</div>
			<div class="team-members">
				<h3>{{team.owner ? 'Team Members:' : 'DISBANDED'}}</h3>
				<ul>
					<li *ngFor="let m of team.members"
						[ngClass]="(pendingInvite(m) || pendingRequest(m)) && !allowedEdit() ? 'hidden' : ''">
						<span>{{m.playerid === player?.playerid ? 'You: ' : ''}}{{m.player_name}} <span
								*ngIf="m.playerid === team.owner.playerid">(Owner)</span><span
								*ngIf="!m.joined && !m.requested">(Invited)</span></span>
						<mat-icon
							*ngIf="m.playerid !== team.owner.playerid && m.playerid !== player?.playerid && allowedEdit() && !pendingRequest(m)"
							aria-hidden="false" aria-label="Remove Player" (click)="startRemove(m)">clear</mat-icon>
						<span *ngIf="pendingRequest(m)" class="request-badge" (click)="startReview(m)">Review
							Request</span>
					</li>
				</ul>
			</div>
		</div>
		<div *ngIf="pendingInvite()" class="accept-invite">
			<h3>You are invited to this team!</h3>
			<div>
				<button class="cancel-btn" (click)="declineInvite()">Decline</button>
				<button (click)="acceptInvite()">Accept</button>
			</div>
		</div>
		<div *ngIf="pendingRequest()" class="accept-invite">
			<h3>You have requested to join this team.</h3>
			<button (click)="declineInvite()">Cancel Request</button>
		</div>
		<div class="team-controls">
			<button *ngIf="allowedEdit()" class="add-btn" (click)="startInvite()">Invite Team Member</button>
			<button *ngIf="eligibleRequest()" class="request-btn" (click)="startAction('request')">Request to
				Join</button>
			<button class="stats-btn"><a href="{{'https://kingtrivia.com/teams/' + team.kt_team_id}}"
					target="_blank">See Stats</a></button>
		</div>
		<div *ngIf="allowedEdit()" class="options" (mouseleave)="showMenu = false">
			<mat-icon aria-hidden="false" aria-label="Options Icon" (click)="showMenu = !showMenu">more_vert</mat-icon>
			<ul *ngIf="showMenu">
				<li (click)="startAction('editTeam')">Team Settings</li>
				<li (click)="startAction('editPic')">Edit Picture</li>
				<li *ngIf="team.owner.playerid === player?.playerid && team.members.length > 1"
					(click)="startAction('newOwner')">Change Owner</li>
				<li *ngIf="team.owner.playerid !== player?.playerid" (click)="startAction('leave')">Leave Team</li>
				<li *ngIf="team.owner.playerid === player?.playerid" (click)="startAction('disband')">Disband Team</li>
			</ul>
		</div>
		<div *ngIf="!player" class="info-alert">
			<h3><span class="sim-link" (click)="toAuth()">Log in</span> to manage this team or request to join!</h3>
		</div>
		<div class="mobile-spacer"></div>
	</div>
	<div *ngIf="!loading && !team" class="content">
		<h2 class="center-text">Team not found</h2>
	</div>
	<div *ngIf="loading" class="content">
		<h1 class="center-text">One Moment...</h1>
	</div>

</div>

<div *ngIf="action === 'newOwner'" class="modal-background">
	<div class="modal-close" (click)="action = ''"></div>
	<div class="modal-container">
		<div class="modal-header">
			<h2>Choose a new owner:</h2>
		</div>
		<div class="modal-content">
			<select [hidden]="loading" (change)="ownerSelect($event.target.value)">
				<option *ngFor="let m of team.members" [value]="m.playerid"
					[selected]="m.playerid === targetPlayer.playerid"
					[ngClass]="pendingRequest(m) || pendingInvite(m) ? 'hidden' : ''">{{m.player_name}}</option>
			</select>
		</div>
		<div *ngIf="!loading" class="modal-footer">
			<button class="cancel-btn" (click)="action = ''">Cancel</button>
			<button (click)="setOwner()">Save</button>
		</div>
	</div>
</div>

<div *ngIf="action === 'editPic'" class="modal-background">
	<div class="modal-close" (click)="action = ''"></div>
	<div class="modal-container">
		<div class="modal-header">
			<h2>Edit Team Picture</h2>
		</div>
		<div class="modal-content">

			<p *ngIf="imageToCropLoaded">Select part of image to crop:</p>
			<image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
				format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
				(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

			<div class="hrz-between">
				<div class="flex flex-align-center" *ngIf="croppedImage">
					<input type="checkbox" name="preview" (change)="onShowPreview()" [ngModel]="showPreview"
						id="preview">
					<label for="preview" class="label">Preview</label>
				</div>

				<label for="fileInput" class="file-uploader" [ngClass]="imageToCropLoaded ? 'transform-to-button':''"
					id="fileUploader" (click)="chooseFile()">
					<span>{{ croppedImage ? 'Change Photo':'Choose Photo' }}</span>
				</label>
			</div>


			<div id="photo-preview" #photoPreview *ngIf="showPreview"></div>

			<input type="file" name="fileInput" accept="image/*" (change)="onFileSelected($event)" #inputFile
				class="hidden">

			<p *ngIf="uploadMessage !== '' " class="std-mt text-red-500">{{ uploadMessage }}</p>
		</div>
		<div *ngIf="!loading" class="modal-footer">
			<button class="bg-transparent" (click)="action = ''">Cancel</button>
			<button (click)="onUpload()" [disabled]="uploading">{{ uploading ? 'Uploading...': 'Upload Photo'
				}}</button>
		</div>
	</div>
</div>

<div *ngIf="action === 'removePlayer'" class="modal-background">
	<div class="modal-close" (click)="action = ''"></div>
	<div class="modal-container">
		<div class="modal-header">
			<h2>Really kick {{targetPlayer.player_name}} from your team?</h2>
		</div>
		<div *ngIf="!loading" class="modal-footer">
			<button class="cancel-btn" (click)="action = ''">Cancel</button>
			<button (click)="removePlayer()">Remove Player</button>
		</div>
	</div>
</div>

<div *ngIf="action === 'request'" class="modal-background">
	<div class="modal-close" (click)="action = ''"></div>
	<div class="modal-container">
		<div class="modal-header">
			<h2>Request to join this team?</h2>
		</div>
		<div class="modal-content">
			<p>You will be added to the team if a member of the team approves your request.</p>
		</div>
		<div *ngIf="!loading" class="modal-footer">
			<button class="cancel-btn" (click)="action = ''">Cancel</button>
			<button (click)="requestJoin()">Request</button>
		</div>
	</div>
</div>

<div *ngIf="action === 'reviewRequest'" class="modal-background">
	<div class="modal-close" (click)="action = ''"></div>
	<div class="modal-container">
		<div class="modal-header">
			<h2>{{targetPlayer.player_name}} has requested to join your team</h2>
		</div>
		<div *ngIf="!loading" class="modal-footer">
			<button class="cancel-btn" (click)="removePlayer()">Deny</button>
			<button (click)="approvePlayer()">Approve</button>
		</div>
	</div>
</div>

<div *ngIf="action === 'leave'" class="modal-background">
	<div class="modal-close" (click)="action = ''"></div>
	<div class="modal-container">
		<div class="modal-header">
			<h2>Really leave this team?</h2>
		</div>
		<div *ngIf="!loading" class="modal-footer">
			<button class="cancel-btn" (click)="action = ''">Cancel</button>
			<button (click)="leaveTeam()">Leave Team</button>
		</div>
	</div>
</div>

<div *ngIf="action === 'disband'" class="modal-background">
	<div class="modal-close" (click)="action = ''"></div>
	<div class="modal-container">
		<div class="modal-header">
			<h2>Really Disband this Team?</h2>
		</div>
		<div *ngIf="team.members.length > 1" class="modal-content">
			<p><strong>Disbanding a team is permanent and will affect all players on this team.</strong> To leave the
				team without disbanding, please <span class="sim-link" (click)="startAction('newOwner')">choose a new
					team owner</span> first.</p>
		</div>
		<div *ngIf="!loading" class="modal-footer">
			<button class="cancel-btn" (click)="action = ''">Cancel</button>
			<button (click)="disbandTeam()">Disband Team</button>
		</div>
	</div>
</div>

<div *ngIf="action === 'editTeam'" class="modal-background">
	<div class="modal-close" (click)="action = ''"></div>
	<div class="modal-container">
		<div class="modal-header">
			<h2>Team Settings</h2>
		</div>
		<div *ngIf="!loading" class="modal-content">
			<h1 class="team-header">Team #{{team.kt_team_id}}:</h1>
			<input type="text" [(ngModel)]="editName" class="name-edit" placeholder="Team Name">
			<input type="text" [(ngModel)]="editSlogan" class="slogan-edit" placeholder="Team Slogan (optional)">
			<div *ngIf="player.playerid === team.owner.playerid" class="lg-my hrz-between flex-align-center">
				<h3 class="toggle-item">All Members Can Edit</h3>
				<div class="toggler" [ngClass]="!restrictEdit ? 'toggle-on' : ''"
					(click)="restrictEdit = !restrictEdit">
					<div class="toggle-channel"></div>
					<div class="toggle-slide" [ngClass]="!restrictEdit ? 'toggle-active' : 'toggle-inactive'"></div>
				</div>
			</div>
			<div *ngIf="player.playerid === team.owner.playerid" class="lg-my hrz-between flex-align-center">
				<h3 class="toggle-item">Allow Join Requests</h3>
				<div class="toggler" [ngClass]="!noRequests ? 'toggle-on' : ''" (click)="noRequests = !noRequests">
					<div class="toggle-channel"></div>
					<div class="toggle-slide" [ngClass]="!noRequests ? 'toggle-active' : 'toggle-inactive'"></div>
				</div>
			</div>
		</div>
		<div *ngIf="loading" class="content">
			<h1 class="center-text">One Moment...</h1>
		</div>
		<div *ngIf="!loading" class="modal-footer">
			<button class="cancel-btn" (click)="action = ''">Cancel</button>
			<button [disabled]="!editName" (click)="saveTeam()">Save</button>
		</div>
	</div>
</div>