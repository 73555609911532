<div class="image-manager-box">
	<span class="image-manager-menu-toggle">
		<svg (click)="hide()"  width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-arrow-bar-right push-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" d="M10.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L12.793 8l-2.647-2.646a.5.5 0 0 1 0-.708z"/>
			<path fill-rule="evenodd" d="M6 8a.5.5 0 0 1 .5-.5H13a.5.5 0 0 1 0 1H6.5A.5.5 0 0 1 6 8zm-2.5 6a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 1 0v11a.5.5 0 0 1-.5.5z"/>
		</svg>
	</span>
	<h1 class="center-text image-manager-title">King Trivia Action Shots!</h1>
	<h3 class="image-manager-description">Send us your best images of this King Trivia game in action! Your Quizmaster's favorite images will be included on our website and entered into our monthly photo contest!</h3>
	<div class="editor-space">
		<app-image-multi-editor [game]="game"></app-image-multi-editor>
	</div>
</div>