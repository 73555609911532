<div *ngIf="currentAd" class="msg-box">
	<div>
		<div *ngIf="currentAd.image" class="picture-box">
			<a href="{{currentAd.link}}" target="_blank">
				<img src="{{currentAd.image}}">
			</a>
		</div>
		<h2 *ngIf="currentAd.text" class="center-text">
			<a href="{{currentAd.link}}" target="_blank">{{currentAd.text}}</a>
		</h2>
	</div>
</div>

