import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TeamService } from '../services/team.service';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-team-restore',
  templateUrl: './team-restore.component.html',
  styleUrls: ['./team-restore.component.scss']
})
export class TeamRestoreComponent implements OnInit {

	result: QueryResult;
	searchid: number;
	err = ""
	loading: boolean;
	player: Player;

	constructor(
		private teams: TeamService,
		private auth: AuthService,
		private router: Router) { }

	ngOnInit(): void {
		this.player = this.auth.getPlayer()
	}

	findTeam() {
		if (this.searchid < 10000) {
			this.err = "Please enter a valid team number."
			return;
		}
		this.loading = true;
		this.teams.getTeamDetail(this.searchid.toString())
			.subscribe(
				(res: TeamRecord) => {
					this.result = {success: true, on_team: false, message: "Player not on team"}
					for (let m of res.members) {
						if (m.playerid === this.player.playerid) {
							this.result.on_team = true;
							this.result.message = "Team Found"
							if (!m.joined) {
								if (m.requested) {
									this.result.requested = true;
								}
								else this.result.invited = true;
							}
						}
					}
					this.loading = false;
				},
				err => {
					if (err.status === 404) this.restoreTeam();
					else {
						this.err = err.error.detail;
						this.loading = false;
					}
				})
	}

	restoreTeam() {
		this.loading = true;
		this.teams.restoreTeam(this.searchid.toString())
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: QueryResult) => this.result = res,
				err => {
					if (err.status === 404) {
						this.result = {success: false, on_team: false, message: "Team Not Found"}
					}
					else this.err = err.error.detail;
				})
	}

	reset() {
		this.result = null;
		this.searchid = undefined;
	}

	toTeams() {
		this.router.navigate(['/team/list']);
	}

	toTeamPage(kt_team_id: number) {
		this.router.navigate([`/team/${kt_team_id.toString()}`]);
	}

}

type QueryResult = {
	success: boolean,
	on_team: boolean,
	message: string,
	requested?: boolean,
	invited?: boolean
}
