import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-capture',
  templateUrl: './image-capture.component.html',
  styleUrls: ['./image-capture.component.scss']
})
export class ImageCaptureComponent implements OnInit {

	@Output() upload: EventEmitter<FileList> = new EventEmitter()

	constructor() { }

	ngOnInit(): void {

	}

	uploadFiles(e: any) {
		this.upload.emit(e.target.files);
	}

}
