<div class="content-container">
	<div class="content">
		<div *ngIf="err" class="hrz-center">
			<h3 class="error-message">{{err}}</h3>
		</div>
		<div class="games-list" *ngIf="games">
			<ng-container *ngIf="games && games.length > 0; else nogames">
				<div class="title">Choose your game below:</div>
				<div>
					<div class="hrz-stretch game-list-header">
						<div class="title-col">TITLE</div>
						<div class="status-col">STATUS</div>
						<div class="button-col"></div>
					</div>
					<div *ngFor="let game of games" class="hrz-stretch game-info">
						<div class="title-col">
							<div class="hrz-stretch">
								<img width="80" class="rounded-avatar" [src]="game.team.team_image" alt="team_image">
								<div class="sm-ml">
									<strong><span class="text-capitalize">{{game.title}}</span> at
										{{game.venue}}</strong>
									<br>
									<span *ngIf="game.team">Team: {{game.team.team_name}}</span>
									<br>
									<em> {{game.date | date:'mediumDate'}}, {{game.date | date:'shortTime'}}</em>
								</div>
							</div>
						</div>
						<div *ngIf="!game.declined" class="status-col">
							<span [class.red]="game.status === 'live' && !game.invite">{{game.invite ? 'invited' :
								game.status}}</span>
							<div *ngIf="!game.loading" class="button-box">
								<button *ngIf="!game.invite" class="accept-btn" [disabled]="game.status !== 'live'"
									(click)="joinExisting(game)">Go To Game</button>
								<button *ngIf="game.invite" (click)="linkJoin(game)">Accept</button>
								<button *ngIf="game.invite" class="decline-btn"
									(click)="declineInvite(game)">Decline</button>
							</div>
							<h3 *ngIf="game.loading">One Moment...</h3>
						</div>
						<div *ngIf="game.declined" class="status-col">
							<span>Invite Declined</span>
						</div>
					</div>
				</div>
				<h3>- OR -</h3>
				<p class="join">
					<button class="join" (click)="joinGame()">Join Game with Code</button>
				</p>
			</ng-container>
			<ng-template #nogames>
				<div class="empty-message">
					<!-- <p>Try joining a game from the menu above.</p> -->
					<div class="title blank-title">Ready to play?</div>
					<button class="join" (click)="joinGame()">Join a Game</button>
					<img src="/assets/img/leaning.png" width="80%">
				</div>
			</ng-template>
		</div>
	</div>
</div>