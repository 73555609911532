export class Game {
	gameid: string
	kt_id: number
	date: string
	title: string
	venue: string
	status: string
	me: Player
	my_team: Team
	host_venmo = []
	round_index: number | null
	submissions_open: boolean
	game_started: boolean
	round: Round
	submitted: any[]
	suggested: any = {}
	private_event: boolean

	constructor(gm) {
		this.gameid = gm.gameid;
		this.kt_id = gm.kt_id;
		let d = new Date(gm.date);
		this.date = d.toLocaleString();
		this.title = gm.title
		this.venue = gm.venue
		this.status = gm.status
		this.submitted = gm.submitted
		this.me = gm.me
		this.my_team = gm.team
		this.round_index = gm.round_index
		this.submissions_open = gm.submissions_open;
		this.game_started = gm.game_started;
		if (gm.round) this.round = new Round(gm.round);
		this.sanitizeDupeQuestions()
		this.sortPlayers();
		if (gm.suggested) this.suggested = gm.suggested;
		if (gm.host_venmo?.length) this.host_venmo = gm.host_venmo;
		this.private_event = gm.private_event
	}

	niceTitle() {
		if (this.title === 'trivia' || this.title === 'slam') {
			return (this.title === 'trivia') ? "Pub Quiz" : "Survey Slam";
		}
		else return this.title;
	}

	sanitizeDupeQuestions() {
		if (this.round) {
			let qlist = this.round.questions;
			if (qlist && qlist.length > 1)
				for (let i = qlist.length - 1; i >= 0; i--) {
					let q = qlist[i];
					for (let x = i - 1; x >= 0; x--) {
						let xq = qlist[x];
						if (q.question_id === xq.question_id) {
							qlist.splice(i, 1);
						}
					}
				}
		}
	}

	addInvitedPlayer(data: any) {
		for (let p of this.my_team.players) {
			if (data.email && p.email === data.email) return;
			if (data.phone && p.phone === data.phone) return
		}
		this.my_team.players.push({
			email: data.email,
			phone: data.phone,
			is_captain: false,
			player_name: "",
			playerid: ""
		})
	}

	removeInvitedPlayer(data: any) {
		for (let i = this.my_team.players.length - 1; i >= 0; i--) {
			if (!this.my_team.players[i].playerid) {
				if (data.email && this.my_team.players[i].email === data.email) {
					this.my_team.players.splice(i, 1);
				}
				else if (data.phone && this.my_team.players[i].phone === data.phone) {
					this.my_team.players.splice(i, 1);
				}
			}
		}
	}

	removePlayer(email: string) {
		for (let i = this.my_team.players.length - 1; i >= 0; i--) {
			if (this.my_team.players[i].email === email) {
				this.my_team.players.splice(i, 1);
			}
		}
	}

	removePlayerWithCode(pCode: string) {
		for (let i = this.my_team.players.length - 1; i >= 0; i--) {
			if (this.my_team.players[i].player_code === pCode) {
				this.my_team.players.splice(i, 1);
			}
		}
	}

	loadCacheRound(round: Round) {
		if (!this.round || this.round.round_id !== round.round_id) return;
		this.round.loadCacheRound(round);
	}

	sortPlayers() {
		this.my_team.players.sort((a, b) => {
			if (a.email === this.me.email) return -1;
			else if (a.playerid && !b.playerid) return -1;
			else if (b.playerid && !a.playerid) return 1;
			else return 0;
		})
	}
}

export class Round {
	round_id: number;
	title: string;
	subtitle: string;
	round_order: number;
	doubled: boolean;
	double_or_nothing: boolean;
	single_answer: boolean;
	handout: boolean;
	tiebreaker: boolean;
	player_instructions: string;
	matches: string[] = [];
	questions: Question[] = [];
	submitted: boolean;

	constructor(rn) {
		this.round_id = rn.round_id;
		this.title = rn.title;
		this.subtitle = rn.subtitle;
		this.doubled = rn.doubled;
		this.double_or_nothing = rn.double_or_nothing;
		this.single_answer = rn.single_answer;
		this.handout = rn.handout;
		this.tiebreaker = rn.tiebreaker;
		this.player_instructions = rn.player_instructions;
		if (rn.matches) this.matches = rn.matches;
		this.round_order = rn.round_order;
		if (rn.asked && rn.asked.length) {
			let count = 0;
			for (let q of rn.asked) {
				let nq = new Question(q);
				nq.question_index = count;
				this.questions.push(nq);
				count++;
			}
		}
		this.submitted = rn.submitted;
	}

	saveAnswers() {
		let out = [];
		for (let q of this.questions) {
			out.push(q.answers);
		}
		return out;
	}

	restoreAnswers(backup: any[]) {
		for (let i = 0; i < this.questions.length; i++) {
			if (backup[i]) {
				this.questions[i].answers = backup[i];
			}
		}
	}

	recallQuestion(question_id: number) {
		for (let i = 0; i < this.questions.length; i++) {
			if (this.questions[i].question_id === question_id) {
				console.log("" + this.questions[i].question_id + " vs " + question_id)
				console.log(this.questions[i]);
				this.questions.splice(i, 1);
			}
		}
		this.submitted = false;
	}

	emptyAnswers() {
		for (let q of this.questions) {
			if (q.emptyAnswers()) return true;
		}
		return false;
	}

	askQuestion(q: Question) {
		if (!this.questionAsked(q.question_id)) {
			this.questions.push(q);
		}
	}

	questionAsked(question_id: number) {
		for (let q of this.questions) {
			if (q.question_id === question_id) return true;
		}
		return false;
	}

	loadCacheRound(round: Round) {
		for (let q of this.questions) {
			for (let cq of round.questions) {
				if (cq.question_id === q.question_id) {
					q.loadCacheAnswers(cq.answers);
				}
			}
		}
	}
}

export class Question {
	question_text: string;
	question_index: number;
	num_answers: number;
	question_id: number;
	image: string;
	answers: string[];

	constructor(qst) {
		this.question_text = qst.question_text;
		this.num_answers = qst.num_answers;
		this.question_id = qst.question_id;
		this.image = qst.image;
		this.answers = [];
		for (let i = 0; i < this.num_answers; i++) {
			if (qst.answers && qst.answers[i]) {
				this.answers.push(qst.answers[i]);
			}
			else this.answers.push('');
		}
	}

	loadCacheAnswers(answers: string[]) {
		for (let i = 0; i < this.answers.length; i++) {
			this.answers[i] = answers[i];
		}
	}

	emptyAnswers() {
		for (let a of this.answers) {
			if (!a) return true;
		}
		return false;
	}
}

export type Ad = {
	image: string,
	text: string,
	link: string
}