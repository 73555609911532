<div class="center-text">
	<button (click)="viewSubmissions()">View Submissions</button>
</div>

<div *ngIf="showAnswers" class="modal-background">
  <div class="modal-close" (click)="showAnswers = false"></div>
  <div class="modal-container">
    <div class="modal-header">
      <h2>Your Past Submissions</h2>
      <i (click)="showAnswers = false" class="material-icons">clear</i>
    </div>
    <div class="modal-content">
    	<h3 class="submit-round-title">Round {{subIndex + 1}}: {{submitted[subIndex] ? submitted[subIndex].title : "(Not Started)"}}</h3>
    	<div *ngIf="submitted[subIndex] && submitted[subIndex].answers?.length; else noSubmit">
    		<p><em>Doubled?: {{submitted[subIndex].doubled ? 'YES' : 'NO'}}</em></p>
    		<div *ngFor="let q of submitted[subIndex].answers; let i = index">
	    		<div *ngIf="!submitted[subIndex].single_answer || i === submitted[subIndex].answers?.length - 1" class="question-confirm">
	    			<div class="hrz-start">
	    				<div *ngIf="!submitted[subIndex].single_answer">Q{{i + 1}}.</div>
		    			<div *ngIf="submitted[subIndex].single_answer">Clue {{i + 1}}:</div>
		    			<div class="answer-confirm">
		    				<div *ngFor="let a of q">{{a}}</div>
		    			</div>
	    			</div>		
	    		</div>
	    	</div>
    	</div>
	    <ng-template #noSubmit>
	    	<div>You didn't submit for this round...</div>
	    </ng-template>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="previousRound()">Previous</button>
      <button class="cancel-btn" (click)="nextRound()">Next</button>
    </div>
  </div>
</div>
