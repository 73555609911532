import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LinkService {

	rootUri = environment.root_uri;

	constructor(private http: HttpClient) { }

	getLongUrl(urlid: string) {
		return this.http.get(`${this.rootUri}/short-url`, {params: {urlid}})
	}
}
