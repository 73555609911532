import { Component, OnInit } from '@angular/core';
import {
  animate, state, style, transition, trigger
} from '@angular/animations';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slide', [
      state('top', style({ transform: 'translateY(0)'})),
      state('down', style({ transform: 'translateY(100%)'})),
      transition('* => *', animate(300))
  ])]
})
export class HeaderComponent implements OnInit {
  showMenu = true;
  menuPosition = 'top';
  missingContact = '';
  warn2FA = false;
  ver = ""
  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        if (event.url.includes('auth')) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }

      }
    });
    this.ver = localStorage.getItem("api_version")
  }

  toggleMenu(): void {
    this.menuPosition = (this.menuPosition === 'top') ? 'down' : 'top';
  }

  navigateTo(event: MouseEvent, screen: string): void {
    event.stopPropagation();

    if (screen === 'auth') {
      this.authService.setToken(null);
    }

    if (screen === 'profile') this.warn2FA = false;

    this.menuPosition = 'top';
    this.router.navigate([`/${screen}`]);
    // setTimeout(() => this.router.navigate(['/' + screen]), 300);
  }

  navigateToExternal(link: string) {
  	window.open(`https://${link}`, "_blank");
  }

  set2FABanner(user: User) {
  	if (!user.email) this.missingContact = 'email'
  	if (!user.phone) this.missingContact = 'phone number'
  	if (this.missingContact) {
  		this.warn2FA = sessionStorage.getItem('dismissed_2fa_warn') ? false : true;
  	}
  }

  dismiss2FA() {
  	this.warn2FA = false;
  	sessionStorage.setItem('dismissed_2fa_warn', 'yes')
  }

}
