import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GameService } from '../services/game.service';
import * as jwtJsDecode from 'jwt-js-decode';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-token-invite',
  templateUrl: './token-invite.component.html',
  styleUrls: ['./token-invite.component.scss']
})
export class TokenInviteComponent implements OnInit {

	err = ""
	loading = true;
	token: string;
	teamName: string;
	gameid: string;
	teamCode: string;
	inviter: string;
	email = "";
	phone = "";
	contactType: 'email' | 'phone';

	invalid: boolean;

	sent: boolean;

	constructor(private router: Router, private route: ActivatedRoute, private gameServe: GameService) { }

	ngOnInit(): void {
		this.route.params
		.subscribe(
			(params: any) => {
				this.token = params.token;
				let data = this.parseJwt(this.token)
				this.teamName = data.team_name
				this.gameid = data.gameid
				this.teamCode = data.team_code
				this.inviter = data.inviter
				if (localStorage.getItem('auth_token')) {
					this.router.navigate([`/auth/link/join/${data.gameid}/team/${data.team_code}`])
				}
				this.loading = false;
			})
	}

	parseJwt(token) {
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
		    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		return JSON.parse(jsonPayload);
	};

	typeSwitch(type: 'email' | 'phone') {
		this.contactType = type;
		this.email = ""
		this.phone = ""
		this.invalid = false
	}

	activeContact() {
		return this[this.contactType];
	}

	updateContact(contact: string) {
		this[this.contactType] = contact;
		this.invalid = !(this.validContact())
	}

	validContact() {
		return ((this.contactType === 'email' && this.validEmail()) || (this.contactType === 'phone' && this.validPhone()))
	}

	validEmail(): boolean {
		// tslint:disable-next-line:max-line-length
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return (this.email && this.email.length > 0 && re.test(String(this.email).toLowerCase()));
	}

	validPhone() {
		return (this.phone && this.phone.length === 10);
	}

	sendInvite() {
		this.err = ""
		this.loading = true;
		this.gameServe.sendSelfInvite(this.gameid, this.teamCode, this.inviter, this.email, this.phone)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				res => this.sent = true,
				err => this.err = err.error.detail)
	}

}
