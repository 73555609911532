<div class="overlay">
	<span class="close-overlay" (click)="navigateBack()">X</span>
	<div class="logo sm-mb">
      <img src="/assets/img/KTL_LOGO.png">
    </div>
	<div [ngClass]="playerid ? 'hidden' : ''">
	    <h2 class="center-text">Log in with your email or phone number</h2>
	    <div [ngClass]="isSending ? 'hidden' : ''" class="hrz-center">
			<app-contact-chooser
				[contactType]="contactType"
				[needName]="needName"
				[loading]="loading"
				[invalidInput]="invalidInput"
				(updateContact)="updateContact($event)"
				(updateName)="name = $event"
				(typeSwitch)="handleTypeSwitch($event)"
				(submit)="handleSubmit()"></app-contact-chooser>
		</div>
		<h2 class="center-text" *ngIf="isSending">Sending your verification code...</h2>
	</div>
	<div *ngIf="playerid" class="content">
		<app-code-verify-input
			[isSending]="isSending"
			[verifying]="verifying"
			[contact]="email ? email : phone"
			[err]="err"
			[resendInSec]="resendInSec"
			(cancel)="playerid = ''"
			(submit)="verifyCode($event)"
			(resend)="sendCode()"></app-code-verify-input>
	</div>

	<p class="bottom-link">King Trivia <a class="a-buffer" href="https://kingtrivia.com/king-trivia-privacy-policy/" target="_blank">Privacy Policy</a> and <a class="a-buffer" href="https://kingtrivia.com/king-trivia-website-terms-of-use/" target="_blank">Terms of Use</a></p>
</div>

	
