<div class="content-container">
	<div class="content">
		<h1 class="center-text" class="title">Create a Team!</h1>
		<div *ngIf="!isLoggedIn; else signUp">
			<h3>Ready to make your team official? <span class="sim-link" (click)="toAuth()">Log in</span> to get
				started!</h3>
			<div class="hrz-center">
				<button (click)="toAuth()">Log In</button>
			</div>
		</div>
	</div>
</div>

<ng-template #signUp>
	<div class="reasons">
		<ul>
			<li>Choose a name</li>
			<li>Add your teammates</li>
			<li>Track your scores</li>
			<li>Compete in <a href="https://kingtrivia.com/leagueplay" target="_blank">League Play!</a></li>
		</ul>
		<p *ngIf="err" class="error-message">{{err}}</p>
		<div *ngIf="!loading" class="signup-form">
			<div class="flex hrz-start items-start w-100 std-mb">
				<app-dice-button (buttonClick)="generateTeamName()"></app-dice-button>

          <app-team-name class="flex-1" [(model)]="team_name"></app-team-name>

			</div>
			<input type="text" [(ngModel)]="team_slogan" placeholder="Team Slogan (optional)">
			<button (click)="createTeam()">Create Team</button>
		</div>
		<h1 *ngIf="loading" class="center-text">Creating Your Team...</h1>
	</div>
</ng-template>
