<div class="content-container">
	<div class="content">
		<h1 *ngIf="loading" class="load-message">One Moment...</h1>

		<h1 *ngIf="!loading && err" class="error-message">{{err}}</h1>

		<div *ngIf="!loading && !sent" class="get-name">
			<h1 class="center-text">You're invited to play King Trivia with '{{teamName}}'</h1>
			<label>Enter an email or phone number to join the game</label>
			<app-contact-chooser
				[contactType]="contactType"
				[loading]="loading"
				[invalidInput]="invalid"
				[noSubmit]="true"
				(updateContact)="updateContact($event)"
				(typeSwitch)="typeSwitch($event)"></app-contact-chooser>
			<button class="lg-mt" [disabled]="(!email && !phone) || !validContact()" (click)="sendInvite()">Send me the join link!</button>
		</div>

		<h1 class="center-text" *ngIf="sent">Invite sent to {{activeContact()}}!</h1>
	</div>
</div>
