import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { tap } from 'rxjs/operators';
import { GameService } from '../services/game.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Game, Round, Question } from '../classes/Game';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
  animations: [
    trigger('slide', [
      state('hide', style({ transform: 'translateX(0)' })),
      state('show', style({ transform: 'translateX(-100%)' })),
      transition('* => *', animate(300))
  ])]
})
export class GameComponent implements OnInit {
  game_id: string;
  game: Game;
  // round_index: number;
  menuPosition = 'hide';
  imgMenuPosition = 'hide'
  //doubleOrNothing = false;

  ws: WebSocket;
  wsAttempts = 0;
  deadWS: boolean = null;

  err = "";
  invErr = ""

  confirmSub: boolean;
  tipQM: boolean;

  isLoggedIn: boolean;

  captainMsg = ""

  showImgUpload: boolean;
  didSSPreview: boolean;

  refreshButtonLink: string | null = null;

  heartbeat;

  constructor(
    private acitvatedRoute: ActivatedRoute,
    private router: Router,
    private gameService: GameService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.acitvatedRoute.params
    .subscribe( (params: any) => {
      this.game_id = params.id;
      this.connectWS();
      this.setVisibilityListener();
    });
    this.isLoggedIn = this.authService.getToken() ? true : false;
    this.checkPreviewed();

    this.refreshButtonLink = null;

  }

  ngOnDestroy() {
    this.deadWS = true;
    console.log("closing connection", this.ws);
    this.ws.close();
    clearInterval(this.heartbeat)
  }

  getCounterSlug() {
	let player = this.authService.getPlayer()
	let pid = player ? `${player.playerid}-` : '';
	let dt = new Date();
	return `${pid}res-${dt.getUTCFullYear()}-${dt.getUTCMonth() + 1}-${dt.getUTCDate()}`;
  }

  checkPreviewed() {
	let key = `${this.getCounterSlug()}-previewed`
	let previewed = localStorage.getItem(key);
	this.didSSPreview = previewed ? true : false;
	console.log(this.didSSPreview)
  }

  registrationPrompt() {
  	if (this.isLoggedIn && this.game?.my_team) {
  		if (!this.game.my_team.kt_team_id) {
  			return sessionStorage.getItem('dismissed_team_register') ? false : true
  		}
  	}
  	return false
  }

  setVisibilityListener() {
    document.addEventListener('visibilitychange', (event) => {
      console.log(document.visibilityState);
      if (document.visibilityState === 'visible') {
        if (!this.ws || this.ws.readyState === WebSocket.CLOSED) {
          this.connectWS();
        }
      }
    })
  }

  toAuth() {
  	let params: any = {previous: window.location.pathname}
  	this.router.navigate(['/auth'], {queryParams: params});
  }

  loadGame() {
    this.gameService.loadGame(this.game_id)
      .subscribe( (game: any) => {
        if (!this.deadWS) {
          this.err = "";
          this.invErr = ""
        }
        let cacheRound;
        if (this.game) cacheRound = this.game.round;
        this.game = new Game(game);
        if (cacheRound) this.game.loadCacheRound(cacheRound);
        this.restoreAnswers();
        if (this.registrationPrompt()) this.toggleMenu()
        console.log('set game:', this.game);
      },
      err => {
        // if (err.error.status_code === 401) {
        //   localStorage.removeItem("auth_token");
        //   this.router.navigate(['/auth']);
        // }
        this.err = err.error.detail;
      });
  }

  resetConnection() {
    if( !this.refreshButtonLink ){
      this.deadWS = null;
      this.wsAttempts = 0;
      this.err = "Attempting to connect...";
      this.connectWS();
    }else{
      this.router.navigate([this.refreshButtonLink]);
    }
  }

  sendHeartbeat() {
    if (this.ws) {
      this.ws.send("thump thump");
    }
    else console.log("No Websocket available for sending heartbeat")
  }

  async connectWS(): Promise<void> {
      if (this.ws) {
        this.ws.close();
      }

      let g = await this.gameService.loadGame(this.game_id).toPromise()

      let host = (!environment.host) ? window.location.host : environment.host;
      let client_id = localStorage.getItem('kt_client_id');
      this.ws = new WebSocket(`${environment.ws.protocol}://${host}/api/game/ws/${this.game_id}/player/${client_id}/${g.team.team_code}`);

      this.ws.onerror = (event) => {
        if (document.visibilityState === "visible") {
          this.err = "Attempting to connect...";
          console.log('WS error:', event);
          if (this.wsAttempts < 4) {
            this.wsAttempts++;
            this.connectWS();
          }
          else {
            this.err = "Connection Interrupted";
            this.deadWS = true;
          }
        }
      };

      this.ws.onclose = (event) => {
        if (this.deadWS === null && document.visibilityState === "visible") {
          this.deadWS = false;
          this.ws.onerror(event);
        }
      }

      this.ws.onopen = (event) => {
        const token = this.authService.getToken();
        const data = {type: 'JOIN', token, client_id: localStorage.getItem('kt_client_id')};
        this.loadGame();
        this.ws.send(JSON.stringify(data));
        if (!this.heartbeat) this.heartbeat = setInterval(this.sendHeartbeat.bind(this), 150000);
        this.refreshButtonLink = "";
        this.err = "";
        this.deadWS = null;
        console.log('WS opened:', event);
      };

      this.ws.onmessage = (event) => {
        let data = JSON.parse(event.data);
        if( data.hasOwnProperty('data') ){
          data = JSON.parse(data.data);
        }
        console.log('WS Data:', data);
        this.refreshButtonLink = null;
        switch (data.type) {

          case 'WEBSOCKET_ERROR':
            console.log("[I] WEBSOCKET ERROR");
            this.deadWS = true;
            this.wsAttempts = 5;
            this.err = "Connection Interrupted";
            this.ws.close();
            break;

          case 'NO_GAME_ACCESS':
            console.log("[I] NO GAME ACCESS");
            this.deadWS = true;
            this.wsAttempts = 5;
            this.err = data.error ?? "Server refused access.";
            this.refreshButtonLink = "/";
            this.ws.close();
            break;

          case 'ASK': // ask single question
            for (let q of data.questions) {
              if (!this.game.round?.questionAsked(q.question_id)) {
              	this.game.round.askQuestion(new Question(q));
              	if (this.game.round.single_answer) {
	            	let len = this.game.round.questions.length;
	            	let ans = [];
	            	if (len > 1) {
	            		for (let a of this.game.round.questions[len - 2].answers) {
		            		ans.push(a);
		            	}
		            	this.game.round.questions[len - 1].answers = ans;
		            }
	            }
              }
            }
            this.game.game_started = true;
            if (data.open_submissions) this.game.submissions_open = true;
            break;

          case 'RECALL': // un-ask a question
            this.game.round.recallQuestion(data.question_id)
            break;

          case 'SUBMIT_ANSWER':
            this.game.round.submitted = true;
            let submission = {
            	doubled: data.doubled,
            	answers: data.answered,
            	single_answer: data.single_answer,
            	title: data.title,
            	round_id: data.round_id
            }
            this.game.submitted[data.round_order] = submission;
            console.log(this.game.submitted);
            break;

          case 'SET_DOUBLE':
            this.game.round.doubled = data.doubled;
            break;

          case 'ROUND_START': // start round
            this.checkPreviewed();
            if (data.restart) {
            	this.loadGame();
            	break;
            }
            this.game.round_index = data.round_index;
            this.game.round = new Round(data.round);
            break;

          case 'ROUND_END': // end round
            this.game.round = null;
            this.game.round_index = null;
            // this.doubleOrNothing = false;
            break;

          case 'SUGGEST':
            delete data.type;
            this.game.suggested = data;
            for (const key of Object.keys(this.game.suggested)) {
              this.game.round.questions[key].suggestions = this.game.suggested[key];
            }
            this.cdr.markForCheck();
            break;

          case 'PROMOTE_CAPTAIN':
            console.log(data)
            if (data.player_code === this?.game?.me?.player_code) {
            	this.game.me.is_captain = true
            	this.captainMsg = "You"
            }
            else this.captainMsg = ""

            this.game.my_team.players = this.game?.my_team?.players.map(x => {
                if (x.player_code === data.player_code) {
                  this.captainMsg = this.captainMsg ? this.captainMsg : x.player_name
                  return {...x, is_captain: true};
                }
                return {...x, is_captain: false};
              });
            break;

          case 'OPEN_SUBMISSIONS':
            this.game.submissions_open = true;
            break;

          case 'CLOSE_SUBMISSIONS':
            this.game.submissions_open = false;
            if (!data.keep_round) {
            	this.game.round_index = null;
            	this.game.round = null;
            }
            break;

          case 'RESET_SUBMISSION':
            if (data.team_code === this.game?.my_team?.team_code) {
              if (this.game.round) this.game.round.submitted = false;
              for (let i = 0; i < this.game.submitted.length; i++) {
              	let s = this.game.submitted[i];
              	if (s && s.round_id === data.round_id) {
              		this.game.submitted[i] = undefined;
              		break;
              	}
              }
            }
            break;

          case 'TEAM_SUBMITTED':
            if (data.team_code === this.game?.my_team?.team_code) {
              this.game.round.submitted = true;
            }
            break;

          case 'CAPTAIN_SUGGEST':
            if (!this.game?.me?.is_captain) {

            }
            break;

          case 'PLAYER_INVITE':
            this.game.addInvitedPlayer(data);
            break;

          case 'CANCEL_INVITE':
            this.game.removeInvitedPlayer(data);
            break;

          case 'DROP_PLAYER':
            if (data.player.player_code === this.game.me.player_code) {
              this.router.navigate(['/']);
            }
            this.game.removePlayerWithCode(data.player.player_code);
            break;

          case 'PLAYER_JOIN':
            const d = data.player;
            const player: Player = {
              email: d.email,
              player_name: d.player_name,
              is_captain: d.is_captain,
              playerid: d.playerid,
              player_code: d.player_code,
              client_id: d.client_id
            };
            let found = this.game?.my_team?.players?.find(p => p.player_code === player.player_code);
            if (!found) {
              found = this.game?.my_team?.players?.find(p => p.email === player.email);
              if (found) {
              	found.player_name = player.player_name;
              	found.playerid = player.playerid;
              	found.player_code = player.player_code;
              	found.profile_img = player.profile_img;
              	found.client_id = player.client_id;
              }
              else this.game?.my_team?.players?.push(player);
            } else {
              found.player_name = player.player_name;
              found.playerid = player.playerid;
            }
            break;

          case 'REGISTER_TEAM':
          	this.game.my_team.kt_team_id = data.kt_team_id;
          	break;

          case 'KICK_TEAM':
            this.router.navigate(['/']);
            break;

          case 'END_GAME':
            this.game.status = 'ended';
            break;

          case 'RELOAD_GAME':
            this.game.status = 'live';
            break;
        }
      };
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  submitRound(): void {
    const answers = [];
    const r_idx = this.game.round_index;
    this.confirmSub = false;
    let q_count = 1;
    for (const q of this.game?.round?.questions) {
      let q_answers = [];
      for (let a of q.answers) {
        a = a.toLowerCase();
        for (let ua of q_answers) {
          if (ua === a) a = "";
        }
        if (this.game.round.single_answer) {
          if (q_count < this.game.round.questions.length) {
            a = "";
          }
        }
        q_answers.push(a);
      }
      answers.push(q_answers);
      q_count++;
    }

    this.gameService.submitRound(this.game_id, r_idx, answers, this.game.round.doubled)
    .subscribe(result => {
      console.log('submit round:', result);
      this.game.round.submitted = true;
    });
  }

  changedAnswer(question: any, q_idx: number, a_idx: number, value: string): void {
    console.log('changedAnswer', value);
    question.answers[a_idx] = value;

    this.backupAnswers();

    // if (!this.game.me.is_captain) {
    //   this.gameService.suggestAnswer(this.game_id, this.game.round_index, q_idx, value)
    //   .subscribe( (result: any) => {

    //   });
    // }
  }

  backupAnswers() {
  	if (!this.game.round) return;
  	let backup = this.game.round.saveAnswers();
  	sessionStorage.setItem(
  		`game_${this.game.gameid}_round_${this.game.round.round_id}`,
  		JSON.stringify(backup)
  	)

  	let sessionRounds = sessionStorage.getItem(`game_${this.game.gameid}_rounds`);
  	let rounds = (sessionRounds) ? JSON.parse(sessionRounds) : {};
  	rounds[`r_${this.game.round.round_id}`] = true;
  	sessionStorage.setItem(`game_${this.game.gameid}_rounds`, JSON.stringify(rounds));
  }

  restoreAnswers() {
  	if (!this.game.round) return;
  	let backup = sessionStorage.getItem(`game_${this.game.gameid}_round_${this.game.round.round_id}`)
  	if (backup) {
  		this.game.round.restoreAnswers(JSON.parse(backup));
  	}
  }

  suggest(q_idx: number, a_idx: number, value: string): void {

    if (!this.game.me.is_captain) {
      this.gameService.suggestAnswer(this.game_id, this.game.round_index, q_idx, value)
      .subscribe( (result: any) => {});
    } else {
      console.log("game:", this.game)
      this.game.round.questions[q_idx].answers[a_idx] = value;
      console.log("game after:", this.game)
      this.cdr.markForCheck();
    }
  }

  trackSuggestionText(index: number, obj: any): any {
    return obj.text;
  }

  toggleMenu(menu: string = "menuPosition"): void {
    if (this[menu] === 'show') {
    	this[menu] = 'hide';
    	if (menu === "menuPosition" && this.isLoggedIn && !this.game.my_team.kt_team_id) {
    		sessionStorage.setItem('dismissed_team_register', 'yes')
    	}
    }
    else this[menu] = 'show'
  }

  toggleDouble(): void {
    if (!this.game.me.is_captain) return;
    else if (this.game && this.game.round) {
      this.game.round.doubled = !this.game.round.doubled;
      this.gameService.toggleDouble(this.game.gameid, this.game.round_index, this.game.round.doubled)
        .subscribe((res: any) => {},
          err => {
            this.game.round.doubled = !this.game.round.doubled;
          })
    }
  }
}
