import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Game } from '../classes/Game';
import { InviteTeammateModalComponent } from '../modals/invite-teammate-modal/invite-teammate-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { GameService } from '../services/game.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-game-team-manager',
  templateUrl: './game-team-manager.component.html',
  styleUrls: ['./game-team-manager.component.scss']
})
export class GameTeamManagerComponent implements OnInit {

	@Input() game: Game
	@Input() isLoggedIn: boolean
	@Input() invErr: string
	@Input() regPrompt: boolean

	@Output() sanity: EventEmitter<boolean> = new EventEmitter()
	@Output() closeToggle: EventEmitter<boolean> = new EventEmitter()
	@Output() auth: EventEmitter<boolean> = new EventEmitter()
	@Output() err: EventEmitter<string> = new EventEmitter()

	inviting: boolean;
	promoting: boolean;
	dropping: boolean;
	registering: boolean;

	toPromote: Player;
	playerKick: Player;

	teams: Team[];
	chosenTeam: {teamid?: string, team_code: string};

	constructor(public dialog: MatDialog, private gameService: GameService) { }

	ngOnInit(): void {

	}

	startUpgradeTeam() {
		this.registering = true;
		this.gameService.getTeams()
			.subscribe(
				(res: Team[]) => this.teams = res,
				err => {
					this.err.emit(err.error.detail)
					this.teams = []
					this.registering = false})
	}

	cancelUpgradeTeam() {
		this.registering = false;
		this.teams = undefined;
		this.chosenTeam = undefined;
	}

	setTeam(teamid: string) {
		this.chosenTeam = {
			team_code: this.game.my_team.team_code,
			teamid
		}
	}

	registerTeam() {
		this.registering = false;
		this.gameService.upgradeTeam(this.game.gameid, this.chosenTeam.team_code, this.chosenTeam.teamid)
			.subscribe(
				res => {},
				err => this.err.emit(err.error.detail))
	}

	inviteTeammate(): void {
	    if (this.inviting || this.dropping) return;
	    const dialogRef = this.dialog.open(InviteTeammateModalComponent, {
	      width: '350px',
	      data: {game: this.game}
	    });

	  dialogRef.afterClosed().subscribe((data: {'email': string, 'phone': string}) => {
	      if (data) {
	        this.inviting = true;
	        this.gameService.inviteTeammate(this.game.gameid, this.game.my_team.team_code, data)
	          .pipe(finalize(() => this.inviting = false))
	          .subscribe(
	          	result => this.err.emit(""),
	          	err => this.err.emit(err.error.detail));
	      }
	    });
	  }

	  cancelInvite(player: Player): void {
	    this.gameService.cancelInvite(this.game.gameid, this.game.my_team.team_code, player)
	      .subscribe(result => {
	        this.err.emit("");
	      },
	      err => {
	        this.sanityCheck();
	      });
	  }

	  dropPlayer(p: Player) {
	    if (this.inviting) return;
	    this.err.emit("")
	    this.dropping = true;
	    this.playerKick = null;
	    this.gameService.dropPlayer(this.game.gameid, this.game.my_team.team_code, p)
	      .subscribe(
	        res => {
	          this.dropping = false;
	        },
	        err => {
	          this.sanityCheck();
	        })
	  }

	  promoteCaptain(player: any): void {
	    if (this.inviting) return;
	    this.inviting = true;
	    this.toPromote = null;
	    this.gameService.promoteCaptain(this.game.gameid, player.player_code, this.game.my_team.team_code)
	      .pipe(finalize(() => this.inviting = false))
	      .subscribe(
	      	result => {},
	      	err => this.err.emit(err.error.detail));
	  }

	  toggleMenu() {
	  	this.closeToggle.emit(true)
	  }

	  captainsName() {
	    for (let t of this.game.my_team.players) {
	      if (t.is_captain) return t.player_name;
	    }
	    return '';
	  }

	  tempName(player: Player) {
	    if (player.email) {
	    	let regex = /[^@]+/
	    	return player.email.match(regex)[0];
	    }
	    else {
	    	return `XXX-XXX-${player.phone.substring(6)}`
	    }
	  }

	  toAuth(){
	  	this.auth.emit(true)
	  }

	  sanityCheck() {
	    this.err.emit("Something's off... Performing sanity check...")
	    this.sanity.emit(true);
	  }
}
