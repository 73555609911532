import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ada-message',
  templateUrl: './ada-message.component.html',
  styleUrls: ['./ada-message.component.scss']
})
export class AdaMessageComponent implements OnInit {

  show: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  setModal(e, val: boolean) {
    this.show = val;
    e.stopPropogation();
  }

}
