import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, HostListener, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-team-selection',
  templateUrl: './custom-team-selection.component.html',
  styleUrls: ['./custom-team-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomTeamSelectionComponent),
      multi: true
    }
  ]
})
export class CustomTeamSelectionComponent implements OnInit {
  @Input() placeholder: string = "Select";
  @Input() items: any[] = [];
  @Input() optionKeyForValue: string = "value";
  @Input() optionKeyForLabel: string = "label";
  @Output() selectedValueChange = new EventEmitter<any>();
  @ViewChild('dropdown') dropdown: ElementRef;

  private innerValue: any;
  private onChange: (_: any) => void = () => { };
  private onTouched: () => void = () => { };

  constructor(private elementRef: ElementRef,) { }

  ngOnInit(): void {
  }


  toggleDropdown() {
    this.dropdown.nativeElement.classList.toggle('active');
  }

  onItemClick(item: any) {
    this.innerValue = item[this.optionKeyForValue];
    this.onChange(this.innerValue);
    this.onTouched();
    this.selectedValueChange.emit(this.innerValue);
    this.toggleDropdown();
    this.placeholder = item[this.optionKeyForLabel]
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const dropdown = this.elementRef.nativeElement.querySelector('.custom-dropdown');
    if (!dropdown.contains(event.target as Node)) {
      dropdown.classList.remove('active');
    }
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    this.innerValue = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
